// vars

$dd-shadow: 0px 15px 20px 0 rgba(29, 29, 29, 0.2);
$dd-content-width: 220px;
$e-dropdown-content-max-height: 500px;
$e-dropdown__title_-max-width: calc(100% - 17px);

// styles

.e-dropdown {
  position: relative;

  &__open {
    @include display-flex;
    @include align-items(center);
    cursor: pointer;
    font-weight: $fw-medium;

    &:after {
      margin-left: $h-unit-xs;
    }

    &.open {
      &:after {
        @include prefix(
          transform,
          rotate($rotate-hor),
          ("webkit", "ms", "moz")
        );
      }
    }
  }

  &__content {
    width: $unit100p;
    min-width: $dd-content-width;
    max-height: $e-dropdown-content-max-height;
    padding: $unit24 $h-unit-l;
    position: absolute;
    top: $v-unit-l;
    left: $unit50p;
    background-color: $white;
    overflow: hidden;
    pointer-events: initial;
    @include prefix(box-shadow, $dd-shadow, ("webkit", "ms", "moz"));
    @include prefix(border-radius, $v-unit-xs, ("webkit", "ms", "moz"));
    @include prefix(transform, translateX(-$unit50p), ("webkit", "ms", "moz"));
    @include transition(
      max-height $ts-fast,
      padding-top $ts-fast,
      padding-bottom $ts-fast
    );
    &:not(.active) {
      padding-top: 0;
      padding-bottom: 0;
      pointer-events: none;
      max-height: 0;
    }

    ul {
      li {
        line-height: $lh-small;
        button {
          text-align: left;
        }
        &:not(:last-child) {
          margin-bottom: $unit12;
        }
      }
    }

    a,
    button {
      color: $dark;

      @include touch-hover() {
        color: $primary;
      }
    }
  }

  .site-header & {
    .e-dropdown__open {
      color: $white;

      &:after {
        content: url(#{$backgrounds}/dd_white.svg);
      }
    }
  }

  &.e-dropdown__laws {
    .e-dropdown__title {
      max-width: $e-dropdown__title_-max-width;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    button {
      font-size: $fs-m;
      font-weight: $fw-normal;
      line-height: $lh-standard;
      color: $black;
      text-align: left;

      &:hover {
        color: $primary;
      }
    }
    li {
      margin-top: $h-unit-xs;
    }
    .e-dropdown__content {
      z-index: 50;
      width: auto;
      left: 0;
      transform: translateX(0);
    }
  }
}
