.story-container {
  div {
    div.react-swipeable-view-container {
      box-shadow: none !important;
      .ReactSwipeableBottomSheet--open {
        margin-top: 10px;
      }
    }
  }
}

[class*='ReactSwipeableBottomSheet--'] {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  &::before {
    content: '';
    display: block;
    margin: 15px auto 0;
    background-color: rgba(31, 31, 31, 0.2);
    width: 50px;
    height: 4px;
    border-radius: 7px;
  }
}

[id='draggable-card'] {
  text-align: center;
  color: black;
}

.children {
  height: 100%;
  > * {
    margin-top: 0;
  }
}

.sharetab {
  height: 100%;
  width: 100%;
  background-color: white;
  text-align: left;
  &__container {
    padding: 25px;
    border-bottom: 1px solid rgba(31, 31, 31, 0.2);
    @include respond-above(xs) {
      padding: 10px;
    }
    @include respond-above(pw) {
      padding: 20px;
    }
  }
  &__pretitle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 5px;
    color: rgba(31, 31, 31, 0.6);
    gap: 10px;
    &--text {
      font-size: 12px;
    }
  }
  &__title {
    h1 {
      font-size: 22px;
      font-weight: bold;
    }
    margin-bottom: 10px;
  }
  &__summary {
    font-weight: normal;
    overflow: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 40px;
    margin-bottom: 10px;
    margin-right: 10px;
    color: rgba(31, 31, 31, 0.6);
    line-height: 20px;
    @media (max-width: 1700px) and (min-width: 415px) {
      font-size: 14px;
    }
  }
  &__parlamentia {
    margin-top: 10px;
    font-size: 12px;
    color: rgba(31, 31, 31, 0.6);
  }
  &__links {
    display: flex;
    overflow-x: auto;
    gap: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-bottom: 5px;
    padding-right: 10px;
    > a {
      display: flex;
      flex-direction: column;
      gap: 5px;
      svg {
        width: 20px;
        height: 20px;
      }
      img {
        width: 20px;
        height: 20px;
        filter: grayscale(1) invert(1);
      }
    }
    &::-webkit-scrollbar {
      height: 5px;
    }
    &::-webkit-scrollbar,
    &::-webkit-scrollbar-thumb {
      overflow: visible;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(146, 145, 145, 1);
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 60px;
      flex: 0 0 60px;
      border: 1px solid rgba(146, 145, 145, 0.2);
      border-radius: 10px;
      background-color: rgba(146, 145, 145, 0.1);
      font-size: 10px;
      font-weight: 500;
      color: black;
    }
  }
  &__related {
    padding: 25px;
    @include respond-above(xs) {
      padding: 10px;
    }
    @include respond-above(pw) {
      padding: 20px;
    }
    h2 {
      color: rgba(31, 31, 31, 0.6);
      margin: 8px 0;
      font-size: 16px;
    }
  }
  &__list {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding-right: 4px;
    max-height: calc(100vh - 390px);
    @include respond-above(xs) {
      max-height: 150px;
    }
    @include respond-above(pw) {
      max-height: 270px;
    }
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar,
    &::-webkit-scrollbar-thumb {
      overflow: visible;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(146, 145, 145, 1);
    }
  }
  &__child {
    display: flex;
    border-bottom: 1px solid rgba(31, 31, 31, 0.2);
    padding: 16px 0;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    @include respond-between(xs, pw) {
      padding: 8px 0;
    }
    &--info {
      display: flex;
      flex-direction: column;
      gap: 5px;
      a {
        color: black;
        font-weight: 500;
        letter-spacing: 0.5px;
        font-size: 16px;
        line-height: 24px;
      }
      p {
        font-size: 12px;
      }
    }
    > img {
      width: 80px;
      height: 80px;
      border-radius: 5px;
      object-fit: cover;
    }
  }
  &__child:last-child {
    padding: 0;
    padding-top: 16px;
    border: none;
    @include respond-between(xs, pw) {
      padding-top: 8px;
    }
  }
}
