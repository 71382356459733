// vars

$card-wrapper-height:			316px;
$card-wrapper-height-xs:	280px;
$card-after-height:				80px;
$card-gradient:						to bottom, transparent , $white;

// styles

.card-storie {
	position: relative;
	margin-top: $unit12;

	&__wrapper {
		max-height: $card-wrapper-height;
		padding-top: $v-unit;
		padding-bottom: $unit48;
		padding-left: $h-unit;
		padding-right: $h-unit;
		overflow: auto;
		background-color: $white;
		@include prefix(border-radius, $v-unit, ('webkit', 'ms', 'moz'));

		/* styling scrollbar in firefox */
		scrollbar-width: thin;
		scrollbar-color: $dark10 transparent;
	}

	/* styling scrollbar */
	&__wrapper::-webkit-scrollbar {
		width: $h-unit-xs;
		height: $v-unit-xs;
	}

	&__wrapper::-webkit-scrollbar-track {
		background: none;
	}

	&__wrapper::-webkit-scrollbar-thumb {
		background-color: $dark10;
		border: 0;
		@include prefix(border-radius, $v-unit, ('webkit', 'ms', 'moz'));
	}

	@include after() {
		position: absolute;
		bottom: 0;
		left: 0;
		width: $unit100p;
		height: $card-after-height;
		background: $white;
		background: -webkit-linear-gradient($card-gradient);
		background: -moz-linear-gradient($card-gradient);
		background: -o-linear-gradient($card-gradient);
		background: linear-gradient(rgba(255,255,255,0), $white);
		@include prefix(border-radius, 0 0 $v-unit $v-unit, ('webkit', 'ms', 'moz'));
	}

	@include respond-below(xs) {

		&__wrapper {
			max-height: $card-wrapper-height-xs;
		}
	}
}