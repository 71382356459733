/* container */

$container-width: 1125px;

.container {
  width: $unit100p;
  max-width: $container-width;
  margin: 0 auto;

  -webkit-animation: fadein 200ms ease-in-out;
  -moz-animation: fadein 200ms ease-in-out;
  -ms-animation: fadein 200ms ease-in-out;
  -o-animation: fadein 200ms ease-in-out;
  animation: fadein 200ms ease-in-out;

  @include respond-below(lg) {
    padding-left: $h-unit;
    padding-right: $h-unit;
  }
}

/* grid */

.grid {
  @include respond-above(sm) {
    @include grid-wrapper(repeat(12, 1fr), $unit24);
  }
}

/* sleeve & overlay */

.figure {
  position: relative;

  img {
    @include absolute;
    @include prefix(object-fit, cover, ("webkit", "ms", "moz", "o"));
  }
}

.sleeve,
.overlay {
  @include absolute;
  @include cover;
  @include prefix(transition, $ts-soft, ("webkit", "ms", "moz", "o"));
}

/* hide & show */

.visible-xs {
  @include respond-above(sm) {
    @include hideit();
  }
}

.hidden-xs {
  @include respond-below(sm) {
    @include hideit();
  }
}

/* e-back */

.e-back {
  @include display-flex;
  @include align-items(center);
  padding: $v-unit-m $h-unit-m;
  background-color: $white;
  border-bottom: $border-xs $dark10;
  font-size: $fs-s;
  font-weight: $fw-semibold;
  line-height: $lh-normal;

  &:before {
    content: url(#{$backgrounds}/mobile_nav.svg);
    margin-right: $unit24;
  }
}

/* filter-input */

.filter-input {
  padding: $v-unit $h-unit;
  @include placeholder-color($black40!important);
  padding-left: $h-unit;

  &__wrapper {
    @include display-flex;
    @include align-items(center);
    padding-left: $h-unit-m;

    &:before {
      content: url(#{$backgrounds}/ico_search.svg);
    }
  }

  @include respond-below(sm) {
    &__wrapper {
      background-color: $white;
      border-bottom: $border-xs $dark10;
    }
  }
}

/* e-submit */

.e-submit {
  @include respond-below(sm) {
    position: absolute;
    bottom: 0;
    left: 0;
    width: $unit100p;

    .btn {
      width: $unit100p;
    }
  }
}

/* e-reset */

.e-reset {
  color: $primary-dark;
  font-family: $ff-main;

  @include respond-above(sm) {
    position: absolute;
    top: $v-unit;
    right: $h-unit;
    font-weight: $fw-medium;

    .e-filter__submit & {
      z-index: 6;
    }
  }

  @include respond-below(sm) {
    position: fixed;
    top: $unit24;
    right: $h-unit-m;
    font-size: $fs-s;
    font-weight: $fw-semibold;
  }
}

/* e-play */

.e-play {
  @include absolute-center;
  content: url(#{$backgrounds}/play.svg);
}

/* pretitle */

.c-pretitle {
  color: $dark60;
  line-height: $lh-small;
}

/* backgrounds & colors */

body.storie {
  background-color: $space;
}

body.single {
  background-color: $space;
  background-image: url(#{$backgrounds}/single_bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;

  -webkit-animation: fadein 700ms ease-in-out;
  -moz-animation: fadein 700ms ease-in-out;
  -ms-animation: fadein 700ms ease-in-out;
  -o-animation: fadein 700ms ease-in-out;
  animation: fadein 700ms ease-in-out;
}

.red {
  color: $red;
}
.green-d {
  color: $green-d;
}


@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}