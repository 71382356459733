// Graphik

@include font-face(
  "Graphik",
  "../../fonts/Graphik-Bold",
  700,
  normal,
  woff woff2
);
@include font-face(
  "Graphik",
  "../../fonts/Graphik-Semibold",
  600,
  normal,
  eot svg ttf woff woff2 woff
);
@include font-face(
  "Graphik",
  "../../fonts/Graphik-SemiboldItalic",
  600,
  italic,
  eot svg ttf woff woff2 woff
);
@include font-face(
  "Graphik",
  "../../fonts/Graphik-Medium",
  500,
  normal,
  eot svg ttf woff woff2 woff
);
@include font-face(
  "Graphik",
  "../../fonts/Graphik-MediumItalic",
  500,
  italic,
  eot svg ttf woff woff2 woff
);
@include font-face(
  "Graphik",
  "../../fonts/Graphik-Regular",
  400,
  normal,
  eot svg ttf woff woff2 woff
);
@include font-face(
  "Graphik",
  "../../fonts/Graphik-RegularItalic",
  400,
  italic,
  eot svg ttf woff woff2 woff
);
@include font-face(
  "Graphik",
  "../../fonts/Graphik-Light",
  300,
  normal,
  eot svg ttf woff woff2 woff
);
@include font-face(
  "Graphik",
  "../../fonts/Graphik-LightItalic",
  300,
  italic,
  eot svg ttf woff woff2 woff
);
