// vars

// styles

.b-vote {
  &__text {
    margin-bottom: $v-unit;
    color: $dark60;
    line-height: $lh-medium;
  }

  &__title {
    color: $dark60;
    margin-bottom: $unit24;
    font-weight: $fw-normal;
    line-height: $lh-medium;
  }

  &__graphic {
    @include display-flex;
    @include flex-wrap(wrap);
    @include justify-content(center);
    position: relative;
    height: $unit48;
    padding-top: $v-unit-xs;

    .si {
      background-color: $yes;
    }
    .noVote {
      background-color: $noVote;
    }
    .abs {
      background-color: $abs;
    }
    .no {
      background-color: $no;
    }

    &:before,
    &:after {
      position: absolute;
      left: $unit50p;
      @include prefix(
        transform,
        translateX(-$unit50p),
        ("webkit", "ms", "moz")
      );
    }

    &:before {
      content: "Mayoría";
      top: -$v-unit;
    }

    @include after() {
      top: 0;
      width: $ls-small;
      height: $unit100p;
      background-color: $dark-soft;
    }
  }

  &__graphic-header {
    @include flex-between;
  }

  &__legend {
    margin-top: $v-unit-l;
    @include prefix(column-gap, $h-unit, ("webkit", "ms", "moz"));

    li {
      @include display-flex;
      @include align-items(center);
      margin-bottom: $v-unit-xs;
      line-height: $lh-big;

      @include before() {
        @include total-size($v-unit-xs);
        margin-right: $unit12;
        @include prefix(border-radius, $unit50p, ("webkit", "ms", "moz"));
      }
    }

    &--alt {
      @include prefix(column-count, 1!important, ("webkit", "ms", "moz"));

      & .b-vote__legend--header {
        @include display-flex;
        @include flex-between;
        @include flex-wrap(nowrap);
        &-wrapper {
          @include after() {
            display: block;
            content: "";
            height: 1px;
            width: $unit100p;
            margin-top: $h-unit-xs;
            margin-bottom: $h-unit;
            background-color: $gray-dark;
          }
        }
        .b-vote__legend--title {
          @include before() {
            display: inline-block;
            min-width: $v-unit-xs;
            width: $v-unit-xs;
            max-width: $v-unit-xs;
            min-height: $v-unit-xs;
            height: $v-unit-xs;
            max-height: $v-unit-xs;
            margin-right: $unit12;
            border-radius: $unit50p;
          }
          &.yes:before {
            background-color: $yes;
          }
          &.noVote:before {
            background-color: $noVote;
          }
          &.abstention:before {
            background-color: $abs;
          }
          &.no:before {
            background-color: $no;
          }
        }
      }

      li {
        @include flex-between;
        @include flex-wrap(wrap);
        @include align-items(center);

        &:before {
          @include hideit();
        }

        .b-vote__legend--wrapper {
          @include display-flex;
          @include align-items(center);

          @include before() {
            @include total-size($v-unit-xs);
            margin-right: $unit12;
            @include prefix(border-radius, $unit50p, ("webkit", "ms", "moz"));
          }
        }
      }
    }
  }

  &--alt {
    .b-vote__graphic {
      &:before {
        @include hideit();
      }
    }
  }

  .e-storie & {
    &__legend {
      @include prefix(column-count, 2, ("webkit", "ms", "moz"));
    }
  }

  @include respond-above(sm) {
    &__legend {
      @include prefix(column-count, 3, ("webkit", "ms", "moz"));
    }
  }
}
