// vars

$card-single-gradient:	360deg, rgba($white,1) 0%, rgba($white,1) $unit50p, rgba($white,0.75) $unit75p, rgba($white,0) $unit100p;

// styles

.card-single {
	padding: $v-unit 0;

	&__inner {

		.c-title {
			line-height: $lh-medium;
		}

		.c-content {
			margin-top: $v-unit-xs;
			color: $dark60;
		}

		&:not(:first-child) {
			border-top: $border-xs $dark10;
		}
	}

	.card__time {
		@include display-flex;
		@include align-items(center);
		margin-top: $v-unit-xs;
		color: $dark60;

		span {
			@include display-flex;

			&:not(:last-child) {

				&:after {
					content: "·";
					margin-left: $ls-big;
					margin-right: $ls-big;
				}
			}
		}

		@include before() {
			@include total-size($v-unit-m);
			background-image: url(#{$backgrounds}/ico_clock.svg);
			background-repeat: no-repeat;
		}
	}

	&--primary {
		background-color: $primary;

		.card-single__inner {
			@include flex-between;
			@include align-items(center);

			&:after {
				content: url(#{$content}/aprobada.svg);
			}
		}
	}

	@include respond-above(sm) {

		&__inner {
			padding-top: $v-unit;
			padding-bottom: $unit24;
			padding-left: $h-unit-l;
			padding-right: $h-unit-l;
		}
	}

	@include respond-below(sm) {

		&__inner {
			padding-left: $h-unit;
			padding-right: $h-unit;

			&:not(:first-child) {
				padding-top: $v-unit;
			}

			&:not(:last-child) {
				padding-bottom: $v-unit;
			}
		}
	}
}