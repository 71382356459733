// vars

$storie-nav-width:					29px;
$storie-nav-opacity:				0.4;
$storie-figure-pb:					95%;
$storie-btn-xs-width:				170px;
$storie-title-size-xs:			36px;

// styles

.e-storie {
	@include flex-direction-column;
	overflow: hidden;
	background-image: url(#{$backgrounds}/storie_bg.jpeg);
	background-position: $unit50p $unit50p;
	background-repeat: no-repeat;
	background-size: cover;

	&__top {
		height: $unit56;
		padding: $v-unit $h-unit;
	}

	&__btn {
		bottom: 0;
	}

	&__nav,
	&__share--list {
		@include display-flex;
	}

	&__nav,
	&__status {
		@include transition(opacity $ts-fast);
	}

	&__nav {

		li {
			width: $storie-nav-width;
			height: $ls-medium;
			background-color: $white;
			@include transition(opacity $ts-fast);

			&:not(:last-child) {
				margin-right: $ls-big;
			}

			&:not(.active) {
				@include prefix(opacity, $storie-nav-opacity, ('webkit', 'ms', 'moz'));
			}
		}
	}

	&__status {
		display: block;
		margin-top: $v-unit-xs;
		color: $white;
		font-size: $fs-xs;
		line-height: $lh-medium;
	}

	&__share {
		position: absolute;
		top: $unit12;

		&--open {
			display: block;
			width: $h-unit-l;
			height: $v-unit-l;
			background-image: url(#{$backgrounds}/ico_share.svg);
			background-position: center;
			background-repeat: no-repeat;
			border: $border-sm $white10;
			@include prefix(border-radius, $unit50p, ('webkit', 'ms', 'moz'));
			cursor: pointer;

			&.open {
				background-image: url(#{$backgrounds}/ico_share_close.svg);
			}
		}

		&--list {
			display: none;
			position: absolute;

			li {
				display: inline-block;

				a {
					@include flex-center;
					width: $h-unit-l;
					height: $v-unit-l;
					background-color: $white10;
					@include prefix(border-radius, $unit50p, ('webkit', 'ms', 'moz'));
				}
			}
		}
	}

	&__inner {
		padding-left: $h-unit;
		padding-right: $h-unit;
	}

	&__title {
		color: $white;
		font-size: $fs-xxxxl;
		font-weight: $fw-bold;
		line-height: $lh-normal;
	}

	&__pretitle {
		color: $primary;
		font-weight: $fw-semibold;
		line-height: $lh-big;
	}

	.c-content {
		margin-top: $v-unit;
		color: $white60;
		font-size: $fs-xl;
	}

	figure,
	.video-wrapper {
		position: relative;
		height: 0;
		margin-bottom: $v-unit-m;
		@include prefix(border-radius, $v-unit, ('webkit', 'ms', 'moz'));
		overflow: hidden;
	}

	&__cats {
		@include display-flex;
		margin-top: $v-unit-l;
	}

	&__cat {
		@include display-flex;
		@include align-items(center);
		height: $unit48;
		padding: $unit12 $h-unit-m;
		background-color: $white30;
		border-radius: $v-unit-xl;
		color: $white;
		font-weight: $fw-semibold;

		i {
			margin-right: $h-unit;
		}
	}

	.item:not(.is-selected) & {

		.e-storie__btn {
			@include hideit();
		}

		.e-storie__nav,
		.e-storie__status {
			@include prefix(opacity, $opacity-none, ('webkit', 'ms', 'moz'));
		}
	}

	@include respond-above(tb) {

		figure,
		.video-wrapper {

			img,
			iframe,
			video {
				@include object-fit;
			}
		}
	}

	@include respond-below(tb) {

		figure,
		.video-wrapper {

			img,
			iframe,
			video {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				@include total-size($unit100p);
				@include prefix(border-radius, $v-unit, ('webkit', 'ms', 'moz'));
			}

			img,
			video {
				object-fit: cover;
			}
		}
	}

	@include respond-above(sm) {
		@include flex-center;
		@include prefix(border-radius, $v-unit, ('webkit', 'ms', 'moz'));

		&__top,
		&__btn {
			position: absolute;
			left: 0;
			width: $unit100p;
		}

		&__top {
			top: 0;
		}

		&__share {
			right: $h-unit;

			&--list {
				top: 0;
				left: $unit100p;
				min-width: $h-unit-xxxl;
				height: $v-unit-l;
				margin-left: $h-unit-l;

				li:not(:last-child) {
					margin-right: $h-unit-xs;
				}
			}
		}

		&__btn {
			@include prefix(border-radius, 0 0 $v-unit $v-unit, ('webkit', 'ms', 'moz'));
			overflow: hidden;
		}

		&__close {
			@include hideit();
		}

		figure {
			padding-bottom: $storie-figure-pb;
		}

		.video-wrapper {
			padding-bottom: $unit66p;
		}
	}

	@include respond-below(sm) {
		@include flex-between;

		&__top {
			position: relative;
			width: $unit100p;
		}

		&__inner {
			margin-top: $unit24;
			margin-bottom: $unit24;
		}

		&__btn {
			@include align-self(center);
			padding-bottom: $unit24;

			.btn {
				width: $storie-btn-xs-width;
				height: $unit48;
				@include prefix(border-radius, $v-unit-xl, ('webkit', 'ms', 'moz'));
			}
		}

		&__share {
			right: $unit48;

			&--list {
				top: $unit100p;
				left: 0;
				min-width: $h-unit-l;
				margin-top: $h-unit;

				li:not(:last-child) {
					margin-bottom: $h-unit-xs;
				}
			}
		}

		&__close {
			content: url(#{$backgrounds}/ico_share_close.svg);
			position: absolute;
			top: $unit50p;
			right: $h-unit;
			@include prefix(transform, translateY(- $unit50p), ('webkit', 'ms', 'moz'));
		}

		figure,
		.video-wrapper {
			padding-bottom: $unit66p;
		}
	}

	@include respond-below(xs) {

		&__inner {
			margin-top: $v-unit-m;
			margin-bottom: $v-unit-m;
		}

		&__btn {
			padding-bottom: $v-unit-m;
		}

		&__title {
			font-size: $storie-title-size-xs;
		}

		.c-content {
			font-size: $fs-l;
		}

		figure,
		.video-wrapper {
			margin-bottom: $v-unit;
			padding-bottom: $unit60p;
		}
	}
}