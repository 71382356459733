$stories-width: 432px;
$stories-height: 768px;

$category-width: 168px;
$category-height: 48px;

.story {
  display: flex;
  flex-direction: column;
  gap: 2px;
  max-height: 60%;
  font-family: Graphik;
  font-style: normal;
  margin-top: -70px;

  &__title {
    color: $white;
    font-weight: 700;
    font-size: 28px;
    margin: 0px 16px;
    &--long {
      color: $white;
      font-weight: 700;
      font-size: 28px;
      margin: 0px 16px;
      span {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        height: 66px;
      }
      @media (max-width: 1700px) and (min-width: 415px) {
        font-size: 28px;
        span {
          height: 58px;
        }
      }
    }

    @media (max-width: 1700px) and (min-width: 415px) {
      font-size: 28px;
    }
  }
  &__pretitle {
    color: $primary;
    margin: 5px 16px;
    font-weight: 600;
    font-size: 14px;
  }
  &__image {
    width: 90%;
    height: auto;
    margin: auto;
    border-radius: 20px;
    margin-bottom: 10px;
  }
  &__video {
    width: 100%;
    height: 100%;
    margin: auto;
    border-radius: 20px;
  }
  &__wrapper {
    display: inline-block;
    position: relative;
    align-self: center;
    width: 90%;
    border-radius: 20px;
    height: 0;
    padding-bottom: calc(90% * 0.56198347107);
    margin-bottom: 10px;
    &--inside {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
  &__wrapper.ended::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    cursor: pointer;
    border-radius: 20px;
    background-color: black;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 64px 64px;
    background-image: url(data:image/svg+xml;utf8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMjgiIGhlaWdodD0iMTI4IiB2aWV3Qm94PSIwIDAgNTEwIDUxMCI+PHBhdGggZD0iTTI1NSAxMDJWMEwxMjcuNSAxMjcuNSAyNTUgMjU1VjE1M2M4NC4xNSAwIDE1MyA2OC44NSAxNTMgMTUzcy02OC44NSAxNTMtMTUzIDE1My0xNTMtNjguODUtMTUzLTE1M0g1MWMwIDExMi4yIDkxLjggMjA0IDIwNCAyMDRzMjA0LTkxLjggMjA0LTIwNC05MS44LTIwNC0yMDQtMjA0eiIgZmlsbD0iI0ZGRiIvPjwvc3ZnPg==);
  }
  &__summary {
    color: $white60;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    overflow: auto;
    margin: 5px 16px;
    @media (max-width: 1700px) and (min-width: 415px) {
      font-size: 14px;
    }
    @include scrollbar();
  }
  &__summary__voting {
    overflow: inherit;
  }
  &__categories {
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    gap: 5px;
    margin: 10px;
    div {
      background: $white30;
      border-radius: 60px;
      height: auto;
      width: auto;
      padding: 2px 5px;
      display: flex;
      gap: 5px;
      justify-content: center;
      align-items: center;
      margin-bottom: 2px;
      @include respond-above(xs) {
        gap: 5px;
      }
      img {
        filter: grayscale(1) invert(1);
      }
      span {
        font-family: Graphik;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        color: $white;
        padding-right: 5px;
        @include respond-above(xs) {
          font-size: 10px;
        }
        @media (min-width: 1700px) {
          font-size: 12px;
        }
      }
    }
  }
  &__amendments {
    padding: 10px 10px 0px 10px;
    max-height: 100%;
    padding-bottom: 20px;
    overflow-y: auto;
    &__container {
      background-color: $white;
      margin: 10px;
      border-radius: 10px;
      margin-top: 15px;
      max-height: 100%;
      position: relative;
      @include respond-above(xs) {
        font-size: 10px;
        max-height: inherit;
      }
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
        height: 60px;
        border-radius: 10px;
      }
    }
    .b-squares {
      position: relative;
      &__title {
        margin-top: 0;
      }
      &__squares {
        gap: 1px;
        > span {
          border: none;
        }
      }
      &__inner-header > span {
        margin-left: 5px;
      }
      &::after {
        content: '';
        width: 100%;
        height: 10px;
        position: relative;
        display: block;
      }
    }
    @include scrollbar();
  }
  &__voting {
    padding: 10px 10px 0px 10px;
    max-height: 100%;
    padding-bottom: 20px;
    overflow-y: auto;
    @include scrollbar();
    &__container {
      background-color: $white;
      margin: 10px;
      border-radius: 10px;
      margin-top: 15px;
      max-height: 70%;
      position: relative;
      align-self: center;
      max-width: 90vw;
      @include respond-above(xs) {
        font-size: 10px;
        max-height: 70%;
        max-width: initial;
        align-self: normal;
      }
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
        height: 60px;
        border-radius: 10px;
      }
    }
    ul > li {
      flex-wrap: nowrap;
      > div {
        flex-wrap: nowrap;
        align-items: center;
      }
    }
    h3.b-vote__title {
      font-size: 14px;
    }
  }
  &__iframe {
    width: 90%;
    height: 100%;
    min-height: 340px;
    align-self: center;
    overflow-y: scroll;
  }
  /* Works on Firefox */
  * {
    scrollbar-width: thin;
  }

  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 12px;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 20px;
  }
}
