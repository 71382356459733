// var

/* main */
$search-pt: calc(#{$v-unit-xxxl} + #{$v-unit});
$search-bg-image: url(#{$backgrounds}/_header.jpeg);
$search-bf-af-bg: rgb(29, 29, 29);
$search-bf-gradient: linear-gradient(0deg, rgba(29, 29, 29, 0) 0%, rgba(29, 29, 29, 1) 100%);
$search-af-gradient: linear-gradient(0deg, rgba(29, 29, 29, 1) 0%, rgba(29, 29, 29, 0) 100%);

/* search top */
$search-top-bf-bg: rgba(#454545, 0.3);
$search-input-w-bf-ct: url(#{$backgrounds}/ico_search.svg);
$search-input-w-bf-top: calc(#{$unit50p} + 2px);
$search-input-pl: calc(#{$unit48} + #{$h-unit-xs});
$search-input-bc: rgba(#000, 0.05);
$search-input-hg-xs: 56px;
$search-input-pl-xs: 54px;

/* search bottom */
$search-bot-pb: calc(#{$unit48} - #{$v-unit-xs});

/* search tabs */
$search-tb-opacity: 0.4;

/* search results */

$search-re-li-p-hor: calc(#{$unit48} + #{$h-unit-xs});
$search-re-box-shad: 0px 15px 20px 0 rgba(29, 29, 29, 0.1);

// styles

/* main styles */

.b-search {
  position: relative;
  @include cover;
  background-image: $search-bg-image;

  &_before,
  &:after {
    background: $search-bf-af-bg;
  }

  &:before,
  &:after,
  &__top > .container:before {
    @include absolute;
    z-index: 0;
  }

  @include before() {
    background: $search-bf-gradient;
  }

  @include after() {
    background: $search-af-gradient;
  }

  &__wrapper {
    & > .c-title {
      margin-bottom: $unit24;
    }
  }

  &__top {
    border-bottom: $border-xs $white;

    & > .container {
      @include before() {
        z-index: 0;
        background-color: $search-top-bf-bg;
      }
    }

    form {
      position: relative;
      z-index: 10;
    }

    .c-title {
      position: relative;
      z-index: 1;
      line-height: $lh-standard;
    }
  }

  &__bottom {
    &,
    &__wrapper {
      position: relative;
      z-index: 1;
    }

    .c-title--s,
    dd {
      color: $white60;
    }
  }

  &__top .c-title,
  dt {
    color: $white;
  }

  &__input-wrapper {
    position: relative;

    &:before {
      content: $search-input-w-bf-ct;
      position: absolute;
      top: $search-input-w-bf-top;
      left: $h-unit;
      @include prefix(transform, translateY(-$unit50p), ('webkit', 'ms', 'moz'));
    }
  }

  input {
    @include display-flex;
    @include align-items(center);
    background-color: $white;
    border: $border-xs $gray;
    caret-color: $primary;
    color: $space;
    @include placeholder-color($black40);
    @include prefix(border-radius, $v-unit-xs, ('webkit', 'ms', 'moz'));
  }

  dl {
    dt {
      font-weight: $fw-medium;
      line-height: $lh-normal;
    }

    dd {
      color: $white60;
      line-height: $lh-medium;
    }
  }

  @include respond-above(tb) {
    &__top {
      .b-search__wrapper {
        @include grid-column(3, 11);
      }
    }

    &__bottom {
      .b-search__wrapper {
        @include grid-column(4, 10);
      }
    }
  }

  @include respond-between(md, tb) {
    &__bottom {
      .b-search__wrapper {
        @include grid-column(3, 11);
      }
    }
  }

  @include respond-between(sm, tb) {
    &__top {
      .b-search__wrapper {
        @include grid-column(2, 12);
      }
    }
  }

  @include respond-between(sm, md) {
    &__bottom {
      .b-search__wrapper {
        @include grid-column(2, 12);
      }
    }
  }

  @include respond-above(sm) {
    padding-top: $search-pt;

    &__top {
      padding-bottom: $v-unit-l;
    }

    input {
      height: $v-unit-xl;
      padding-top: $v-unit;
      padding-bottom: $v-unit;
      padding-left: $search-input-pl;
      padding-right: $h-unit;
      font-size: $fs-l;
    }

    &__bottom {
      padding-top: $v-unit-l;
      padding-bottom: $search-bot-pb;

      .c-title--s {
        margin-bottom: $unit24;
      }

      .b-search__wrapper {
        margin-left: -$unit12;
        margin-right: -$unit12;
      }
    }

    &__bottom-content {
      @include display-flex;
      @include justify-content(space-between);

      dl {
        @include display-flex;
        @include align-items(center);

        &:not(:last-child) {
          margin-right: $unit24;
        }
      }

      dd {
        font-size: $fs-s;
        margin-left: $h-unit-xs;
      }
    }

    #searchMobileBack {
      @include hideit();
    }
  }

  @include respond-below(sm) {
    padding-top: $v-unit-xxl;


    &__top {
      padding-bottom: $v-unit-l;
    }

    &__input-wrapper {
      @include display-flex;
      border-bottom: $border-xs $black5;
      #clearSearch {
        content: url(#{$content}/clear.svg);
        right: $v-unit;
        cursor: pointer;
        position: absolute;
        top: $unit50p;
        @include prefix(transform, translateY(-$unit50p), ('webkit', 'ms', 'moz'));
      }
    }

    &__bottom {
      margin-top: $unit24;
      padding-bottom: $v-unit-l;
    }

    &__bottom-content {
      @include display-flex;

      dl {
        @include total-size($unit33p);

        dt {
          font-size: $fs-xxxxl;
        }

        dd {
          font-size: $fs-xs;
        }

        &:not(:last-child) {
          margin-right: $h-unit-xs;
        }
      }
    }

    input {
      height: $search-input-hg-xs;
      padding-top: $v-unit;
      padding-bottom: $v-unit;
      padding-left: $search-input-pl-xs;
      padding-right: $h-unit;
      font-size: $fs-s;
    }

    .search-focus & {
      .b-search__form.active {
        @include full-fixed;
        z-index: 30;
        background-color: $gray;
      }

      &__input-wrapper.active {
        &:before {
          @include hideit();
        }

        input {
          padding-left: 0;
          border: 0;
          @include prefix(border-radius, 0, ('webkit', 'ms', 'moz'));
        }

        #searchMobileBack {
          @include total-size($unit56);
          background-color: $white;
          background-image: url(#{$backgrounds}/mobile_nav.svg);
          background-position: $unit50p $unit50p;
          background-repeat: no-repeat;
        }
      }
    }
  }
}
