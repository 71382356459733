// vars



// styles

.e-filter-dd {
	position: relative;
	background-color: $white;
	border: $border-xs $dark10;
	@include prefix(border-radius, $v-unit-xs, ('webkit', 'ms', 'moz'));

	&__open {
		@include flex-between;
		position: relative;
		z-index: 1;
		padding: $v-unit-xs $h-unit;
		cursor: pointer;
		font-weight: $fw-medium;

		.e-filter-dd__filter-count {
			margin-left: $h-unit-xs;
		}

		&:after {
			content: url(#{$backgrounds}/dd_black.svg);
			@include prefix(transform, scale(0.9), ('webkit', 'ms', 'moz'));
			margin-left: $h-unit-xs;
		}
	}

	&:last-child {

		&.open {

			.e-filter-dd__open {
				width: $filter-wrap-width;
				justify-content: flex-start;
			}

			.e-filter {
				right: 0;
				left: auto;
			}
		}
	}

	&.open {
		position: relative;
		z-index: 10;
	}

	&.active {
		border-color: $primary;
	}
}
