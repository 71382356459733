// c-title

.c-title {
  color: $black;
  font-weight: $fw-semibold;

  &--xxxxl {
    font-weight: $fw-bold;
  }

  &--xxxl {
    font-weight: $fw-bold;
  }

  @include respond-above(sm) {
    &--xxxxl {
      font-size: $fs-xxl;
    }

    &--xxxl {
      font-size: $fs-xxxl;
    }

    &--xxl {
      font-size: $fs-xxl;
    }

    &--home {
      &--xxxl {
        font-size: $fs-xxxl;
      }
    }


    &--xl {
      font-size: $fs-xl;
    }

    &--mbottom {
      margin-bottom: $h-unit-l;
    }
  }

  @include respond-below(sm) {
    &--xxxxl {
      font-size: $fs-xxl;
    }

    &--xxxl {
      font-size: $fs-xxxl;
    }
    &--home {
      &--xxxl {
        font-size: $fs-xxl;
      }
    }

    &--xxl {
      font-size: $fs-l;
    }

    &--xl {
      font-size: $fs-m;
    }
    &--mbottom {
      margin-bottom: $h-unit-m;
    }
  }
}

.c-title--centered {
  text-align: center;
}
