// vars



// styles

.e-related {

	a {
		@include display-flex;
		padding-top: $v-unit;
		padding-bottom: $v-unit;

		.e-related__title {
			color: $dark;
		}

		.e-related__url {
			color: $dark60;
		}
	}

	&__header {
		padding-right: $unit24;
	}

	&__title,
	&__url {
		line-height: $lh-standard;
	}

	&__title {
		font-size: $fs-m;
		font-weight: $fw-semibold;
	}

	&__url {
		display: inline-block;
		margin-top: $ls-extra;
		font-size: $fs-xs;
	}

	figure {
		position: relative;
		@include total-size(80px);
		@include prefix(border-radius, $v-unit-xs, ('webkit', 'ms', 'moz'));
		overflow: hidden;

		img {
			@include absolute;
			@include object-fit;
		}
	}
}