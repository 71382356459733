// vars

$btn-min-width:					170px;

// styles

.btn {
	position: relative;
	min-width: $h-unit-xxxl;
	height: $unit56;
	padding: $v-unit-m $h-unit-m;
	@include prefix(border-radius, 0, ('webkit', 'ms', 'moz', 'o'));
	cursor: pointer;
	font-size: $fs-m;
	font-family: $ff-main;
	font-weight: $fw-medium;
	line-height: $lh-reset;
	@include prefix(transition, $ts-fast, ('webkit', 'ms', 'moz', 'o'));
}

.btn--primary {
	@include flex-center;

	&:not(.disabled) {
		background-color: $primary;
		color: $dark;
	}

	&.disabled {
		background-color: $gray-dark;
		color: $dark60;
		cursor: not-allowed;
	}

	@include respond-above(tb) {

		&:not(.disabled) {

			&:hover {
				background-color: $primary-light;
			}

			&:active,
			&:focus {
				background-color: $primary-dark;
			}
		}
	}
}

//
// btn-wrapper
//

.btn-wrapper {
	@include display-flex;

	.btn {
		min-width: $btn-min-width;
		@include prefix(border-radius, $v-unit-l, ('webkit', 'ms', 'moz', 'o'));
	}

	&.btn-wrapper--left {
		@include justify-content(flex-start);
	}

	&.btn-wrapper--center {
		@include justify-content(center);
	}
}

