// vars

$author-portrait-size: 44px;
$author-portrait-size-sm: 40px;
$vote-author-max-width: 85%;

// styles

.e-author {
  @include display-flex;
  @include align-items(flex-start);

  &.pointer {
    cursor: pointer;
  }
  .b-vote__legend & {
    max-width: $vote-author-max-width;
  }
  &__portrait {
    @include total-size($author-portrait-size);
    overflow: hidden;
    border: $border-sm $black5;

    &,
    & > span {
      @include flex-center;
      @include prefix(border-radius, $unit50p, ('webkit', 'ms', 'moz'));
    }

    &:last-of-type {
      margin-right: $h-unit-xs;
    }

    &--more {
      background-color: $gray;

      span {
        @include flex-center;
        @include total-size($author-portrait-size-sm);
        border: $border-xs $black5;
        color: $dark;
        font-size: $fs-s;
        font-style: $fs-normal;
        font-weight: $fw-medium;
      }
    }

    &:not(:first-child) {
      margin-left: -$h-unit-xs;
    }
  }

  &__name {
    min-width: $unit50p;
    line-height: $lh-medium;

    dt {
      color: $dark;
      font-weight: $fw-semibold;
    }

    dd {
      color: $dark60;
      font-size: $fs-s;
    }
  }

  &__all {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: $vote-author-max-width;
    & + .e-author__name {
      width: 100%;
    }
  }

  .card-law & {
    @include flex-wrap(wrap);
  }

  .card__bottom & {
    .e-author__portrait {
      background-color: $gray;
      border: $border-xs $black5;
    }
  }

  .modal--authors & {
    dt {
      font-weight: $fw-normal;
    }
  }

  @include respond-above(sm) {
    @include flex-wrap(wrap);

    &__name {
      dt {
        font-size: $fs-m;
      }
    }
  }
}
