// vars

$page-item-mr: 5px;
$page-link-opacity: 0.6;

// styles

.page-numbers {
  margin-top: $v-unit-l;
  margin-bottom: $v-unit-l;

  .pagination {
    @include flex-center;
  }

  .page-item {
    button {
      @include flex-center;
      @include total-size(40px);
      background-color: transparent;
      @include prefix(border-radius, $unit50p, ("webkit", "ms", "moz"));
      color: $dark60;
      font-size: $fs-l;
      @include prefix(opacity, $page-link-opacity, ("webkit", "ms", "moz"));
      @include transition(opacity $ts-fast, background-color $ts-fast);

      &:hover {
        background-color: $gray-dark;
        @include prefix(opacity, $opacity-full, ("webkit", "ms", "moz"));
      }

      &.page-link {
        &.next {
          svg {
            @include prefix(
              transform,
              rotate($rotate-hor),
              ("webkit", "ms", "moz")
            );
          }
        }
      }
    }

    &:not(:last-child) {
      margin-right: $page-item-mr;
    }

    &.active {
      button {
        background-color: $gray-dark;
        @include prefix(opacity, $opacity-full, ("webkit", "ms", "moz"));
      }
    }
  }
}
