/*
	-
	Colors
	-
*/

// primary

$primary: #01f3b3;
$primary-dark: #07d49e;
$primary-light: #80f9d9;
$blueberry: #0086e7;
$raspberry: #f54242;

// secondary

$white: #ffffff;
$white60: rgba($white, 0.6);
$white30: rgba($white, 0.3);
$white10: rgba($white, 0.1);
$gray: #f6f6f6;
$gray-dark: #ebebeb;
$dark: #1f1f1f;
$dark80: rgba($dark, 0.8);
$dark60: rgba($dark, 0.6);
$dark10: rgba($dark, 0.1);
$dark-semi: #808080;
$dark-soft: #cccccc;
$space: #0f0f0f;
$space10: rgba($space, 0.1);

// graphs

$blue: #6595cf;
$red: #dd7461;
$orange: #e39264;
$yellow: #f2cd7a;
$yellow-soft: #fff280;
$green: #bdda6d;
$green-d: #739b67;
$purple: #7f5a81;
$gray-d: #7e7e7e;
$black: #000000;
$black80: rgba($black, 0.8);
$black60: rgba($black, 0.6);
$black40: rgba($black, 0.4);
$black5: rgba($black, 0.05);
$black-l: #333333;

$wrong: #f54242;
$wrong-soft: #fdeae7;

$success: #0f7c34;
$success-soft: #e2f1e6;

$approved: #00e7aa;
$processing: #0086e7;
$not-approved: #5e5e5e;
$rejected: #f54242;

$yes: #01f3b3;
$no: #f74842;
$abs: #f9c63e;
$noVote: #bdbdbd;

/*
	-
	Typos
	-
*/

$ff-main: "Graphik", sans-serif;

$text-indent: -9999px;

/*
	-
	Sizes
	-
*/

$fs-xxxxl: 40px;
$fs-xxxl: 32px;
$fs-xxl: 24px;
$fs-xl: 20px;
$fs-l: 18px;
$fs-m: 16px;
$fs-s: 14px;
$fs-xs: 12px;

$v-unit-xs: 8px;
$v-unit-s: 10px;
$v-unit: 16px;
$v-unit-m: 20px;
$v-unit-l: 32px;
$v-unit-xl: 64px;
$v-unit-xxl: 96px;
$v-unit-xxxl: 120px;

$h-unit-xs: 8px;
$h-unit-s: 10px;
$h-unit: 16px;
$h-unit-m: 20px;
$h-unit-l: 32px;
$h-unit-xl: 64px;
$h-unit-xxl: 96px;
$h-unit-xxxl: 120px;

$unit100p: 100%;
$unit75p: 75%;
$unit66p: 66.66667%;
$unit60p: 60%;
$unit50p: 50%;
$unit45p: 45%;
$unit40p: 40%;
$unit33p: 33.33333%;
$unit25p: 25%;
$unit-video: 56.25%;

$unit56: 56px;
$unit48: 48px;
$unit40: 40px;
$unit24: 24px;
$unit12: 12px;
$unit10: 10px;
$v100: 100vh;

/*
	-
	Styles
	-
*/

$fw-black: 900;
$fw-extrabold: 800;
$fw-bold: 700;
$fw-semibold: 600;
$fw-medium: 500;
$fw-normal: 400;
$fw-light: 300;
$fw-thin: 100;

$fs-italic: italic;
$fs-normal: normal;

$lh-extra: 2;
$lh-big: 1.75;
$lh-read: 1.6667;
$lh-medium: 1.5;
$lh-standard: 1.33333;
$lh-normal: 1.2;
$lh-small: 1.142;
$lh-reset: 1;
$lh-none: 0;

$ls-minor: 0.5px;
$ls-small: 1px;
$ls-medium: 2px;
$ls-big: 3px;
$ls-extra: 4px;

$border-xs: 1px solid;
$border-sm: 2px solid;
$border-md: 3px solid;

$border-radius: 4px;

$ts-fast: 0.3s;
$ts-soft: 0.6s;
$ts-slow: 1s;

$opacity-full: 1;
$opacity-medium: 0.5;
$opacity-none: 0;

$rotate-hor: 180deg;

/*
	-
	path
	-
*/

$backgrounds: "../../img/backgrounds";
$content: "../../img/content";

/*
	-
	project
	-
*/

$header-height: 137px;
$header-height-md: 121px;
$header-height-sm: 50px;
