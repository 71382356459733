$stories-width: 432px;
$stories-height: 768px;

.stories {
  z-index: 1000;
  height: 100%;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__bg {
    z-index: 1;
    background-color: black;
    width: 100%;
    height: 100%;
    position: fixed;
    @include respond-above(xs) {
      display: block;
      padding: 2500px;
      position: absolute;
    }
  }
  &__nav {
    display: none;
    @include respond-above(xs) {
      display: flex;
      padding: 20px;
      background-color: black;
      border-bottom: 1px solid rgba($color: #fff, $alpha: 0.1);
      z-index: 10;
      position: fixed;
      top: 0;
      width: 100vw;
      justify-content: space-around;
      margin-bottom: 70px;
    }
    &--logo > img {
      width: 190px;
    }
    &--close {
      font-size: 20px;
    }
  }
  > ul {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    @include respond-above(xs) {
      height: fit-content;
      position: absolute;
      width: fit-content;
      gap: 15vw;
    }
  }
  .hidden {
    visibility: hidden;
  }
}
