// vars

$search-re-box-shad: 0px 15px 20px 0 rgba(29, 29, 29, 0.1);

// styles
.b-search__form:not(.active) .b-search__results {
  display: none;
}
.b-search__form.active .b-search__results.active {
  display: block;
}

.b-search__results {
  display: none;
  background-color: $white;

  &--list {
    li {
      border-top: $border-xs $gray;

      a {
        @include flex-between;
        @include align-items(center);
        position: relative;
        min-height: $v-unit-xl;
        padding-top: $unit12;
        padding-bottom: $unit12;
        padding-left: $search-re-li-p-hor;
        padding-right: $search-re-li-p-hor;
        color: $black40;
        @include transition(background-color $ts-fast);

        .info {
          @include display-flex;
          @include flex-direction-column;
          line-height: $lh-big;

          .name {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
          .role {
            font-size: $fs-xs;
          }
        }

        mark {
          background-color: transparent;
          color: $black;
          font-style: $fs-normal;
          font-weight: $fw-normal;
        }

        &:before,
        &:after {
          position: absolute;
          top: $unit50p;
          @include prefix(
            transform,
            translateY(-$unit50p),
            ("webkit", "ms", "moz")
          );
        }

        &:before {
          content: url(#{$backgrounds}/ico_search.svg);
        }

        &:after {
          content: url(#{$backgrounds}/ico_external_link.svg);
          right: $h-unit-m;
        }

        @include touch-hover() {
          background-color: $gray;
        }
      }

      .image {
        @include flex-center;
        width: 40px;
        height: 40px;
        min-width: 40px;
        overflow: hidden;
        background-color: $gray;
        @include prefix(border-radius, $unit50p, ("webkit", "ms", "moz"));
      }
    }
  }

  @include respond-above(sm) {
    position: absolute;
    top: $unit100p;
    left: 0;
    z-index: 10;
    width: $unit100p;
    margin-top: -$v-unit-xs;
    border: $border-xs $gray;
    @include prefix(
      border-radius,
      0 0 $v-unit-xs $v-unit-xs,
      ("webkit", "ms", "moz")
    );
    @include prefix(box-shadow, $search-re-box-shad, ("webkit", "ms", "moz"));

    &--list {
      li {
        a {
          .info {
            width: $unit66p;
          }

          &:before {
            left: $h-unit-m;
          }
        }
      }
    }
  }

  @include respond-below(sm) {
    &--list {
      li {
        a {
          &:before {
            left: $h-unit;
          }
        }
      }
    }
  }
}
