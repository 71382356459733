// vars

// styles

.flickity-button {
	@include flex-center;
	background-color: transparent!important;
	background-image: url(#{$content}/stories-arrow.svg)!important;
	background-position: center center!important;
	background-repeat: no-repeat!important;
	border: $border-sm $white10;
	@include prefix(border-radius, $unit50p, ('webkit', 'ms', 'moz'));
	@include prefix(outline, none, ('webkit', 'ms', 'moz'));
	@include prefix(appearance, none, ('webkit', 'ms', 'moz'));
	@include prefix(box-shadow, none!important, ('webkit', 'ms', 'moz'));

	&:hover,
	&:active,
	&:focus {
		background-color: transparent!important;
	}

	&.previous {
	}

	&.next {
		@include prefix(transform, rotate($rotate-hor), ('webkit', 'ms', 'moz'));
	}

	.flickity-button-icon {
		display: none!important;
	}

	@include respond-above(tb) {
		width: $unit48;
		height: $unit48;
	}

	@include respond-between(sm,tb) {
		width: $h-unit-l;
		height: $v-unit-l;
	}

	@include respond-below(sm) {
		width: $unit48;
		height: $unit48;
	}
}

