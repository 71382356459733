// vars

$card-summary-gradient: 360deg, rgba($white, 1) 0%, rgba($white, 1) $unit50p, rgba($white, 0.75) $unit75p, rgba($white, 0) $unit100p;
$card-summary-gradient-inverse: 360deg, rgba($white, 1) 0%, rgba($white, 0.95) $unit75p, rgba($white, 0) $unit100p;
$card-summary-open-max-height: 5000px;
// styles

.card-summary {
  position: relative;

  &__wrapper {
    position: relative;
    height: auto;
    max-height: 300px;
    overflow: hidden;
    @include transition(max-height $ts-fast ease-in-out);

    @include after() {
      position: absolute;
      left: 0;
      width: $unit100p;
      height: $v-unit-xxxl;
      background-image: -webkit-linear-gradient($card-summary-gradient);
      background-image: -moz-linear-gradient($card-summary-gradient);
      background-image: -o-linear-gradient($card-summary-gradient);
      background-image: linear-gradient($card-summary-gradient);
      // @include prefix(border-radius, 0 0 $v-unit $v-unit, ('webkit', 'ms', 'moz'));
      @include prefix(opacity, $opacity-full, ('webkit', 'ms', 'moz'));
      @include transition(height $ts-fast);
    }

    .c-title {
      margin-bottom: $v-unit;
    }

    .c-content {
      h3 {
        margin-bottom: $v-unit-xs;
        color: $dark;
        font-size: $fs-s;
      }
      ul {
        li {
          margin-left: 20px;
        }
      }

      a {
        color: $dark60;
        text-decoration: underline;
      }
      a:hover {
        color: $primary;
      }
    }

    &.open {
      max-height: $card-summary-open-max-height;
      overflow: hidden;

      &:after {
        @include prefix(opacity, $opacity-none, ('webkit', 'ms', 'moz'));
        height: $v-unit-l;
      }
    }
  }

  &__open {
    @include flex-between;
    @include align-items(center);
    position: absolute;
    bottom: 0;
    left: 0;
    width: $unit100p;
    z-index: 1;
    padding-top: $v-unit;
    cursor: pointer;

    &:before {
      content: 'Ver más';
      color: $primary-dark;
      font-weight: $fw-semibold;
    }

    &:after {
      content: url(#{$backgrounds}/dd_primary.svg);
    }

    &.open {
      background-image: -webkit-linear-gradient($card-summary-gradient-inverse);
      background-image: -moz-linear-gradient($card-summary-gradient-inverse);
      background-image: -o-linear-gradient($card-summary-gradient-inverse);
      background-image: linear-gradient($card-summary-gradient-inverse);
      @include prefix(border-radius, 0 0 $v-unit $v-unit, ('webkit', 'ms', 'moz'));
      &:before {
        content: 'Ver menos';
      }

      &:after {
        @include prefix(transform, rotate($rotate-hor), ('webkit', 'ms', 'moz'));
      }
    }
  }

  @include respond-above(sm) {
    &__wrapper {
      padding-bottom: $v-unit-xl;

      &:after {
        bottom: 0;
      }
    }

    &__inner {
      &:not(:last-child) {
        margin-bottom: $unit48;
      }
    }

    &__open {
      padding-bottom: $v-unit-l;
      padding-left: $h-unit-l;
      padding-right: $h-unit-l;
    }
  }

  @include respond-below(sm) {
    &__wrapper {
      padding-bottom: $unit48;

      &:after {
        bottom: -$v-unit;
      }
    }

    &__inner {
      &:not(:last-child) {
        margin-bottom: $v-unit-l;
      }
    }

    &__open {
      padding-bottom: $v-unit;
      padding-left: $h-unit;
      padding-right: $h-unit;
    }
  }
}
