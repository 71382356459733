// vars

$storie-width:						360px;
$storie-height:						640px;
$storie-scale:						0.75;
$stories-margin:					6%;
$stories-fl-btn-pos:			27%;
$stories-fl-btn-pos-tb:		28%;
$stories-fl-btn-pos-md:		21.5%;
$stories-sm-index:				40;

// styles

.b-stories {

	&,
	.container {
		height: $v100;
	}

	.container {
		@include flex-center;
	}

	.carousel {
		width: $unit100p;
	}

	.flickity-button {

		&:disabled {
			@include hideit();
		}
	}

	@include respond-above(tb) {

		&__slide {

			.item {

				&.is-next {
					margin-left: $stories-margin;
				}

				&.is-previous {
					margin-left: - $stories-margin;
				}
			}
		}

		.flickity-button {

			&.previous {
				left: $stories-fl-btn-pos;
			}

			&.next {
				right: $stories-fl-btn-pos;
			}
		}
	}

	@include respond-between(md,tb) {

		.flickity-button {

			&.previous {
				left: $stories-fl-btn-pos-tb;
			}

			&.next {
				right: $stories-fl-btn-pos-tb;
			}
		}
	}

	@include respond-between(sm,tb) {

		.carousel {
			margin-top: $v-unit-xl;
		}
	}

	@include respond-below(md) {

		.container {
			padding-left: 0;
			padding-right: 0;
		}
	}

	@include respond-between(sm,md) {

		.flickity-button {

			&.previous {
				left: $stories-fl-btn-pos-md;
			}

			&.next {
				right: $stories-fl-btn-pos-md;
			}
		}
	}

	@include respond-above(sm) {

		&__slide {

			.item {
				width: $storie-width;
				height: $storie-height;
				padding-left: $ls-small;
				padding-right: $ls-small;
				@include transition(transform $ts-fast);
				
				&:not(.is-selected) {
					@include prefix(transform, scale($storie-scale), ('webkit', 'ms', 'moz'));
				}

				& > * {
					width: $unit100p;
					height: $unit100p;
				}
			}
		}

		.flickity-button {
			top: $unit50p;

			&.previous {
				@include prefix(transform, translateY(- $unit50p), ('webkit', 'ms', 'moz'));
			}

			&.next {
				@include prefix(transform, translateY(- $unit50p) rotate($rotate-hor), ('webkit', 'ms', 'moz'));
			}
		}

		.flickity-page-dots {
			top: 0;
			bottom: auto!important;
			left: $unit50p;
			@include prefix(transform, translateX(- $unit50p), ('webkit', 'ms', 'moz'));
			width: $unit33p;
			padding-top: $v-unit;
			padding-left: $h-unit;
			padding-right: 8%;
			text-align: left;

			.dot {
				width: $h-unit-l;
				height: $ls-medium;
				margin-left: 0;
				background-color: $white;
				@include prefix(border-radius, 0!important, ('webkit', 'ms', 'moz'));

				&:not(:last-child) {
					margin-right: $ls-big;
				}
			}
		}

		&__more-info {
			@include hideit();
		}
	}

	@include respond-below(sm) {
		position: relative;
		z-index: $stories-sm-index;

		.flickity-slider {
			@include prefix(transform, none!important, ('webkit', 'ms', 'moz'));
		}

		&__slide {

			.item {
				width: $unit100p;
				left: 0!important;
				opacity: 0;
				@include prefix(opacity, $opacity-none, ('webkit', 'ms', 'moz'));
				z-index: -1;

				& > * {
					height: $unit100p;
				}

				&.is-selected {
					@include prefix(opacity, $opacity-full, ('webkit', 'ms', 'moz'));
					z-index: 0;
				}
			}
		}

		.flickity-button {
			top: auto;
			bottom: $unit24;

			&.previous {
				left: $h-unit;
				@include prefix(transform, translateY(0), ('webkit', 'ms', 'moz'));
			}

			&.next {
				right: $h-unit;
				@include prefix(transform, rotate($rotate-hor), ('webkit', 'ms', 'moz'));
			}
		}

		&__more-info {
			position: fixed;
			top: 75px;
			height: calc(100% - 75px);
			@include prefix(transform, translateY($unit100p), ('webkit', 'ms', 'moz'));
			@include transition(transform $ts-soft ease-in-out);

			.storie-launched & {
				@include prefix(transform, translateY(0), ('webkit', 'ms', 'moz'));
			}
		}
	}

	@include respond-below(xs) {

		.flickity-button {
			bottom: $v-unit-m;
		}
	}
}

body.storie {

	@include respond-below(sm) {

		&,
		.page-content,
		.b-stories,
		.b-stories > .container,
		.b-stories__slide,
		.b-stories__slide .flickity-viewport,
		.b-stories__slide .flickity-slider,
		.b-stories__slide .item,
		.b-stories__slide .e-storie {
			height: $unit100p;
		}
	}
}