//
// vars
//

$burger-width: 25px;
$burger-height: 2px;
$burger-radio: 1px;
$btn-close-before-transform: 45deg;

//
// styles
//

.e-burger__wrapper {
  @include respond-above(md) {
    @include hideit();
  }

  @include respond-below(md) {
    &:not(.e-burger__wrapper--alt) {
      @include flex-center;
      height: $burger-width;
      padding: 0;
      @include prefix(appearance, none!important, ('webkit', 'ms', 'moz'));
      background: transparent;
      border: 0;
      color: $white;
      line-height: $lh-reset;
      cursor: pointer;
      outline: none !important;
      user-select: none;

      .e-burger {
        position: relative;

        &,
        &:before,
        &:after {
          display: inline-block;
          width: $h-unit-l;
          height: $burger-height;
          background: $white;
          @include prefix(border-radius, $burger-radio, ('webkit', 'ms', 'moz', 'o'));
          @include transition(transform-origin $ts-fast, transform $ts-fast);
        }

        &:before,
        &:after {
          content: '';
          position: absolute;
          left: 0;
        }

        &:before {
          top: $v-unit-xs;
        }

        &:after {
          top: -$v-unit-xs;
        }
      }

      &.btn-close {
        .e-burger {
          background-color: transparent;

          &:before,
          &:after {
            top: 0;
            left: $h-unit-xs;
            @include prefix(transform-origin, $unit50p $unit50p, ('webkit', 'ms', 'moz', 'o'));
            width: $h-unit-m;
          }

          &:before {
            @include prefix(transform, rotate3d(0, 0, 1, $btn-close-before-transform), ('webkit', 'ms', 'moz', 'o'));
          }

          &:after {
            @include prefix(transform, rotate3d(0, 0, 1, -$btn-close-before-transform), ('webkit', 'ms', 'moz', 'o'));
          }
        }
      }
    }
  }
}

.e-burger__wrapper--alt {
  @include respond-below(sm) {
    @include flex-direction-column;
    position: relative;
    top: -1px;

    .e-burger {
      @include display-flex;
      @include align-items(center);
      width: auto;
      height: auto;
      background-color: transparent;

      &:first-child,
      &:last-child {
        position: absolute;
      }

      &:first-child {
        top: -6px;
      }

      &:last-child {
        bottom: -6px;
      }

      &:before,
      &:after {
        @include absolute-reset;
      }

      &:before {
        content: '';
        @include total-size($ls-medium);
        margin-right: $ls-big;
        background-color: $white;
        font-weight: $fw-bold;
      }

      &:after {
        content: '';
        @include absolute-reset;
        width: $h-unit;
        height: $burger-height;
        background: $white;
        @include transition(transform-origin $ts-fast, transform $ts-fast);
      }
    }

    &.btn-close {
      .e-burger {
        &:before {
          @include hideit();
        }

        &:after {
          left: -$h-unit !important;
          @include prefix(transform-origin, $unit50p $unit50p!important, ('webkit', 'ms', 'moz', 'o'));
          width: $h-unit-m !important;
        }

        &:first-child {
          &:after {
            top: $ls-big !important;
            @include prefix(transform, rotate3d(0, 0, 1, $btn-close-before-transform) !important, ('webkit', 'ms', 'moz', 'o'));
          }
        }

        &:nth-child(2) {
          &:after {
            @include hideit();
          }
        }

        &:last-child {
          &:after {
            top: -7px !important;
            @include prefix(transform, rotate3d(0, 0, 1, -$btn-close-before-transform) !important, ('webkit', 'ms', 'moz', 'o'));
          }
        }
      }
    }
  }
}
