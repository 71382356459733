// vars

// styles

.card-law,
.ue {
  padding: $v-unit $h-unit;

  &__header {
    @include display-flex;
    @include flex-direction-row;
    @include align-items(center);
    margin-bottom: $h-unit;
  }

  &__legislature {
    color: $dark60;
  }

  &__status,
  &__text {
    color: $dark60;
  }

  &__status {
    font-size: $fs-m;
    line-height: $lh-small;
    margin: 0px $unit40 0px $h-unit-s;
  }

  &__status--ico {
    min-width: 50px;
  }

  &__title {
    @include transition(color $ts-fast);

    &,
    a {
      color: $dark;
    }

    mark {
      background-color: $yellow-soft;
      font-style: $fs-normal;
      font-weight: $fw-normal;
    }

    &:not(.c-title--xxxxl):not(.c-title--xl) {
      font-size: $fs-m;
      line-height: $lh-medium;
    }

    &.c-title--xl {
      font-size: $fs-xl;
      font-weight: $fw-normal;
    }

    & span.yellow {
      background-color: $yellow-soft;
    }

    &__reference,
    &__legislature {
      color: $dark60;
      font-size: $fs-s;
      width: 100%;
    }

    &__eu {
      width: 35%;
      @include display-flex;
      @include align-items(center);

      &--small {
        width: 18%;
        @include display-flex;
        @include align-items(center);
      }
    }

    &__legislature {
      text-align: end;
      width: 100%;
    }

    &__info {
      @include display-flex;
      @include align-items(center);
      @include justify-content(center);
      margin-bottom: $h-unit-xs;

      &--small {
        @include display-flex;
        @include align-items(center);
        margin-bottom: $h-unit;
      }
    }
  }

  &__text {
    margin-top: $v-unit-xs;
    padding-right: 16.666667%;
    line-height: $lh-medium;
  }

  &__info {
    margin-top: $v-unit-l;
  }

  &__share {
    position: absolute;
    top: $v-unit;
    right: $h-unit;
  }

  &__share-open {
    cursor: pointer;

    &:not(.open) {
      content: url(#{$content}/share.svg);
    }

    &.open {
      content: url(#{$backgrounds}/ico_share_close_card.svg);
    }
  }

  &__share-list {
    display: none;
    position: absolute;
    z-index: 1;

    li {

      a,
      button {
        @include flex-center;
        @include total-size($v-unit-l);
        background-color: $dark-semi;
        @include prefix(border-radius, $unit50p, ('webkit', 'ms', 'moz'));
        @include transition(background-color $ts-fast);

        img {
          width: auto;
          height: $unit45p;
        }

        @include touch-hover() {
          background-color: $primary;

          svg {
            &:not([viewbox='0 0 16 16']) {
              path {
                fill: $black;
              }
            }

            &[viewbox='0 0 16 16'] {
              path {
                stroke: $black;
              }
            }
          }
        }
      }
    }
  }

  &>a {
    @include touch-hover() {
      .card-law__title {
        color: $dark60;
      }
    }
  }

  &.card-single {
    .card-law__title {
      height: auto;
    }
  }

  @include respond-above(sm) {
    &__title {
      height: $v-unit-xxl;

      &.c-title--xxxxl {
        overflow: initial;
        height: auto;
      }

      &--legacy {
        margin-bottom: $h-unit-l;
      }
    }

    &__share-list {
      top: 0;
      min-width: $h-unit-xxxl;

      &:not(.inverse) {
        left: $unit100p;
      }

      &.inverse {
        left: unset;
        right: calc(#{$unit100p} + #{$v-unit});
      }

      ul {
        @include display-flex;
        padding-left: $h-unit;
      }

      li {
        &:not(:last-child) {
          margin-right: $h-unit-xs;
        }
      }
    }

    &.card-single {
      .card-law__share {
        top: $v-unit-l;
        right: $h-unit-l;
      }
    }
  }

  @include respond-below(sm) {
    &__share-list {
      top: $unit100p;
      left: 0;

      ul {
        padding-top: $h-unit;
      }

      li {
        &:not(:last-child) {
          margin-bottom: $h-unit-xs;
        }
      }
    }
  }
}

.ue {
  &__title {
    display: block;
    height: auto;
  }
}

body:not(.single) .card-law__title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  height: auto;
  line-height: 1.1;
}

.card-law__info .e-author {
  flex-wrap: nowrap;
}

body:not(.single) .card-law__info .e-author .e-author__name dt {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}