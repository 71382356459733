// vars

$single-pb: $v-unit-xxxl * 1.5;
$single-container-pt-xs: 75px;
$single-mb-menu-trans: 120%;
$single-section-mb-max-width: 90%;
// styles

.b-single {
  body.single & {
    padding-bottom: 0;
  }
  &__header {
    line-height: $lh-extra;
    border-bottom: $border-xs $white10;

    button {
      @include display-flex;
      @include align-items(center);
      color: $white;
      line-height: $lh-extra;
      &.e-button-back:before {
        content: '';
        @include total-size($unit24);
        margin-right: $h-unit-xs;
        background-image: url(#{$backgrounds}/arrow_left.svg);
        background-repeat: no-repeat;
      }
    }
  }

  &__inner {
    &:not(:last-child) {
      margin-bottom: $v-unit;
    }
  }

  &__nav nav,
  &__sidebar .e-info {
    position: sticky;
    top: $v-unit-xxl;
  }

  @include respond-above(md) {
    padding-bottom: $single-pb;

    .container {
      @include grid-wrapper(repeat(4, 1fr), $h-unit);
      grid-template-areas:
        'head head head head'
        'nav main main sidebar';
    }

    &__header {
      grid-area: head;
    }

    &__nav {
      grid-area: nav;
    }

    &__main {
      grid-area: main;
    }

    &__sidebar {
      grid-area: sidebar;
    }
  }

  @include respond-below(md) {
    &__sidebar {
      @include hideit();
    }
  }

  @include respond-between(sm, md) {
    padding-bottom: $v-unit-xxxl;

    & > .container {
      @include grid-wrapper(repeat(12, 1fr), $h-unit);
    }

    &__nav {
      @include grid-column(1, 5);
    }

    &__main {
      @include grid-column(5, -1);
    }
  }

  @include respond-above(sm) {
    padding-top: $v-unit-xl;

    & > .container {
      padding-top: $v-unit-l;
    }

    &__header {
      padding-top: $v-unit-xs;
      padding-bottom: $v-unit-xs;
    }

    &__nav {
      li {
        &:not(:last-child) {
          margin-bottom: $unit24;
        }
      }

      a,
      button {
        @include display-flex;
        @include align-items(center);
        color: $white;
        line-height: $lh-small;

        @include before() {
          width: $ls-small;
          height: $unit24;
          background-color: $primary;
          @include prefix(opacity, $opacity-none, ('webkit', 'ms', 'moz'));
          @include transition(opacity $ts-fast, margin-right $ts-fast);
        }

        &.active {
          color: $primary;

          &:before {
            margin-right: $h-unit-xs;
            @include prefix(opacity, $opacity-full, ('webkit', 'ms', 'moz'));
          }
        }
      }
    }

    .b-single__inner {
      &:last-child {
        margin-bottom: 50px;
      }
    }
  }

  @include respond-below(sm) {
    &__header {
      position: fixed;
      top: 0;
      left: 0;
      width: $unit100p;
      height: $unit56;
      z-index: 40;
      background-color: $space;
      border-bottom: $border-xs $white10;

      .container {
        @include flex-between;
        @include align-items(center);
      }
    }

    .e-button-back {
      padding-top: $v-unit;
      padding-bottom: $v-unit;
      font-weight: $fw-semibold;
      max-width: $single-section-mb-max-width;
      & #singleSection {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    & > .container {
      padding-top: $single-container-pt-xs;
    }

    &__nav {
      position: fixed;
      top: $unit56;
      left: 0;
      width: $unit100p;
      z-index: 30;
      @include prefix(transform, translateY(-$single-mb-menu-trans), ('webkit', 'ms', 'moz'));
      background-color: $space;
      @include transition(transform $ts-soft ease-in-out);

      li {
        padding: $v-unit $h-unit;

        a,
        button {
          color: $white;

          &.active {
            color: $primary;
          }
        }

        &:not(:last-child) {
          border-bottom: $border-xs $white10;
        }
      }

      .menu-launched--alt & {
        @include prefix(transform, translateY(0), ('webkit', 'ms', 'moz'));
      }
    }

    .b-single__inner {
      &:last-child {
        margin-bottom: 50px;
      }
    }
  }
}
