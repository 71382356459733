// vars

$list-header-wrap-pt: 40px;
$list-no-results-height: calc(#{$v100} - #{$v-unit-xxxl} - 194px);
$list-no-results-height-tb: calc(#{$v100} - #{$v-unit-xxxl} - 260px);
$list-no-results-height-xs: calc(#{$v100} - #{$unit56} - #{$unit56});
$list-no-result-bg-opacity: 0.2;

// styles

.b-list {
  background-color: $gray;

  &.b-list__legacy {
    background-color: $space;
    .c-title {
      color: $white;
      margin-bottom: $h-unit-l;
    }
    .card-law .c-title {
      color: $dark;
    }
  }
  &__header {
    padding-top: $unit24;
  }

  &__header--wrapper {
    @include flex-between;
    @include align-items(center);
  }

  .e-dropdown {
    &__open {
      font-size: $fs-xl;
      font-weight: $fw-bold;
      line-height: $lh-standard;

      &:not(.noArrow):after {
        content: url(#{$backgrounds}/dd_black.svg);
      }
    }
  }

  &__no-results {
    @include display-flex;
    @include flex-direction-column;
    @include align-items(center);

    & > * {
      text-align: center;
    }

    .c-content {
      margin-top: $v-unit;
    }

    &:before {
      content: url(#{$backgrounds}/no_results.svg);
      @include prefix(
        opacity,
        $list-no-result-bg-opacity,
        ("webkit", "ms", "moz", "o")
      );
    }
  }

  @include respond-above(tb) {
    padding-bottom: $unit48;
    &.b-list__legacy {
      padding-top: $v-unit-xxl;
      padding-bottom: $v-unit-xxl;
    }

    &__no-results {
      height: $list-no-results-height;
      padding-top: $v-unit-xl;
    }

    &.no-results {
      padding-bottom: 0;
    }
  }

  @include respond-above(md) {
    .container--grid {
      @include grid-wrapper(repeat(3, 1fr), $unit24);

      .card-info {
        @include grid-column(3, -1);
        grid-row: 2;
      }
    }

    &__no-results {
      max-width: $unit33p;
    }
  }

  @include respond-below(md) {
    .container--grid {
      @include grid-wrapper(repeat(1, 1fr), $unit24);
      row-gap: $unit24;

      .card-info {
        grid-row: 6;
      }
    }
  }
  @include respond-between(sm, md) {
    .container--grid {
      .card-info {
        grid-row: 2;
        grid-column: 2;
      }
    }
  }
  @include respond-between(sm, tb) {
    padding-bottom: $h-unit-l;

    &.b-list__legacy {
      padding-top: $v-unit-xl;
      padding-bottom: $v-unit-xl;
    }

    &__no-results {
      height: $list-no-results-height-tb;
      padding-top: $v-unit-xl;
    }
  }

  @include respond-between(sm, md) {
    .container--grid {
      @include grid-wrapper(repeat(2, 1fr), $unit24);
    }

    &__no-results {
      max-width: $unit50p;
    }
  }

  @include respond-above(sm) {
    .container--grid {
      row-gap: $v-unit;
      grid-auto-rows: 1fr;
    }

    &__header {
      padding-bottom: $unit24;
    }

    &__header-count {
      color: $dark60;
      font-size: $fs-l;
      margin-top: $list-header-wrap-pt;
      margin-bottom: $v-unit-l;
    }

    &__filters {
      @include display-flex;
      @include justify-content(flex-end);
      margin-top: $list-header-wrap-pt;
      margin-bottom: $v-unit-l;

      & > *:not(:last-child) {
        margin-right: $h-unit-xs;
      }
    }

    &__open-filters {
      @include hideit();
    }

    &__no-results {
      margin-left: auto;
      margin-right: auto;

      &:before {
        margin-bottom: $unit40;
      }
    }

    .btn-wrapper {
      margin-top: $unit48;
    }
  }

  @include respond-below(sm) {
    padding-bottom: $unit24;

    &.b-list__legacy {
      padding-top: $unit48;
      padding-bottom: $unit48;
    }

    &__header {
      padding-bottom: $v-unit;
    }

    &__header--wrapper {
      padding-top: $unit24;
      padding-bottom: $v-unit;
    }
    &__open-filters--open {
      color: $primary-dark;
      font-weight: $fw-semibold;
    }

    &__filters {
      @include hideit();
    }
    &.no-results {
      height: $list-no-results-height-xs;
      padding-top: $v-unit-xxl;

      .b-list__open-filters {
        @include hideit();
      }
      .b-list__header-count {
        @include hideit();
      }
      &:before {
        margin-bottom: $unit24;
      }
    }

    .btn-wrapper {
      margin-top: $v-unit-l;
    }
  }
}
