// vars



// styles

.c-share {

	ul {
		@include display-flex;
	}

	.b-single .container > *:last-child & {
		margin-top: $v-unit;
	}

	@include respond-above(tb) {

		.e-featured & {
			margin-bottom: $unit48;
		}

		.b-single__inner + & {
			margin-top: $unit48;
			margin-bottom: $v-unit-xl;
		}
	}

	@include respond-between(sm,tb) {

		.e-featured & {
			margin-bottom: $v-unit-m;
		}

		.b-single__inner + & {
			margin-top: $v-unit-l;
			margin-bottom: $unit48;
		}
	}

	@include respond-above(sm) {

		ul {

			li:not(:last-child) {
				margin-right: $h-unit;
			}
		}
	}

	@include respond-below(sm) {

		ul {

			li:not(:last-child) {
				margin-right: $h-unit-xs;
			}
		}

		img {
			width: $h-unit-l;
		}

		.e-featured & {
			margin-bottom: $v-unit-m;
		}

		.b-single__inner + & {
			margin-top: $v-unit-m;
			margin-bottom: $v-unit-l;
		}
	}
}