// spin

@-moz-keyframes spin { 
	100% { 
		-moz-transform: rotate(360deg); 
	} 
}

@-webkit-keyframes spin { 
	100% { 
		-webkit-transform: rotate(360deg); 
	} 
}

@-o-keyframes spin { 
	100% { 
		-webkit-transform: rotate(360deg); 
	} 
}

@keyframes spin { 
	100% { 
		-webkit-transform: rotate(360deg); 
		transform:rotate(360deg); 
	} 
}

// bounce

// @-webkit-keyframes bounce {
// 	0%, 20%, 50%, 80%, 100% {
// 		-webkit-transform: translateY(0);
// 	} 
// 	40% {
// 		-webkit-transform: translateY(-4px);
// 	}
// 	60% {
// 		-webkit-transform: translateY(-2px);
// 	}
// }
 
// @-moz-keyframes bounce {
// 	0%, 20%, 50%, 80%, 100% {
// 		-moz-transform: translateY(0);
// 	}
// 	40% {
// 		-moz-transform: translateY(-4px);
// 	}
// 	60% {
// 		-moz-transform: translateY(-2px);
// 	}
// }
 
// @-o-keyframes bounce {
// 	0%, 20%, 50%, 80%, 100% {
// 		-o-transform: translateY(0);
// 	}
// 	40% {
// 		-o-transform: translateY(-4px);
// 	}
// 	60% {
// 		-o-transform: translateY(-2px);
// 	}
// }

// @keyframes bounce {
// 	0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
// 	40% {transform: translateY(-4px);}
//  	60% {transform: translateY(-2px);}
// }

// lighting

// @-webkit-keyframes lighting {
// 	0% {
//     @include opacity(.1);
//   }
//   100% {
//     @include opacity(1);
//   }
// }

// @-moz-keyframes lighting {
// 	0% {
//     @include opacity(.1);
//   }
//   100% {
//     @include opacity(1);
//   }
// }

// @-o-keyframes lighting {
// 	0% {
//     @include opacity(.1);
//   }
//   100% {
//     @include opacity(1);
//   }
// }

// @keyframes lighting {
//   0% {
//     @include opacity(0.2);
//   }
//   100% {
//     @include opacity(1);
//   }
// }

// fade

// @-webkit-keyframes fade {
//   0% {
//     @include opacity(1);
//   }
//   33% {
//     @include opacity(.5);
//   }
//   66% {
//     @include opacity(0);
//   }
//   100% {
//   	@include opacity(.5);
//   }
// }

// @-moz-keyframes fade {
//   0% {
//     @include opacity(1);
//   }
//   33% {
//     @include opacity(.5);
//   }
//   66% {
//     @include opacity(0);
//   }
//   100% {
//   	@include opacity(.5);
//   }
// }

// @-o-keyframes fade {
//   0% {
//     @include opacity(1);
//   }
//   33% {
//     @include opacity(.5);
//   }
//   66% {
//     @include opacity(0);
//   }
//   100% {
//   	@include opacity(.5);
//   }
// }

// @keyframes fade {
//   0% {
//     @include opacity(1);
//   }
//   33% {
//     @include opacity(.5);
//   }
//   66% {
//     @include opacity(0);
//   }
//   100% {
//   	@include opacity(.5);
//   }
// }