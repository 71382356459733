// vars



// styles

.card-up {
	position: relative;
	height: $unit100p;
	padding: $v-unit-l 0;
	overflow: auto;
	border-top: $border-xs $dark10;
	@include prefix(border-radius, $v-unit $v-unit 0 0, ('webkit', 'ms', 'moz'));

	#closeCard {
		position: absolute;
		top: $unit12;
		left: $unit50p;
		width: $h-unit-xl;
		height: $ls-extra;
		@include prefix(transform, translateX(- $unit50p), ('webkit', 'ms', 'moz'));
		background-color: $dark10;
		@include prefix(border-radius, $ls-medium, ('webkit', 'ms', 'moz'));

		& + .card-single__inner {
			border-top: 0;
		}
	}

	.c-title {
		height: auto;
	}

	.card-law__text {
		padding-right: 0;
	}

	.card__link {
		display: inline-block;
		margin-top: $v-unit-xs;
		color: $dark60;
		font-size: $fs-xs;
		line-height: $lh-standard;
	}

	.card-single__inner {
		padding-left: $h-unit-l;
		padding-right: $h-unit-l;
	}

	.card__share-mobile {
		position: relative;
		height: 80px;
		margin-top: $v-unit-l;
		margin-left: - $h-unit-l;
		margin-right: - $h-unit-l;
		overflow-x: auto;

		ul {
			@include display-flex;
			position: absolute;
			left: 0;
			padding-left: $h-unit-l;
			padding-right: $h-unit-l;

			li {

				a {
					@include flex-center;
					@include flex-direction-column;
					@include total-size(80px);
					background-color: $gray;
					@include prefix(border-radius, $v-unit, ('webkit', 'ms', 'moz'));
					border: $border-xs $black5;
					color: $dark;
					font-weight: $fw-semibold;

					&:before {
						margin-bottom: $v-unit-xs;
					}

					&.share-link {

						&:before {
							content: url(#{$backgrounds}/ico_link.svg);
						}
					}

					&.share-facebook {

						&:before {
							content: url(#{$backgrounds}/ico_facebook.svg);
						}
					}

					&.share-twitter {

						&:before {
							content: url(#{$backgrounds}/ico_twitter.svg);
						}
					}

					&.share-whats {

						&:before {
							content: url(#{$backgrounds}/ico_whatsapp.svg);
						}
					}
				}

				&:not(:last-child) {
					margin-right: $h-unit;
				}
			}
		}
	}
}