// vars

$height-above-sm: 350px;
$height-below-sm: 200px;

// style
.spinner {
  @include flex-center;
  @include flex-direction-column;
  span {
    margin-top: $unit24;
  }

  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    border-radius: 50%;

    &.black {
      background: rgba(255, 255, 255, 0.3);
      border: 1px solid rgba(255, 255, 255, 0.05);
    }

    &.white {
      background: #ffffff;
      border: 1px solid rgba(31, 31, 31, 0.05);
    }

    &.grey {
      background: #f6f6f6;
      border: 1px solid rgba(31, 31, 31, 0.05);
    }

    span {
    }
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 40px;
    height: 40px;
    margin: 20px;
    border: 4px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #07d49e transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @include respond-above(sm) {
    height: $height-above-sm;
  }
  @include respond-below(sm) {
    height: $height-below-sm;
  }
}
