// Generic

html {
  color: #000;
  background: #fff;
}
blockquote,
body,
code,
dd,
div,
dl,
dt,
fieldset,
form,
h1,
h2,
h3,
h4,
h5,
h6,
input,
legend,
li,
ol,
p,
pre,
td,
textarea,
th,
ul {
  margin: 0;
  padding: 0;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
fieldset,
img {
  border: 0;
}
address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
  font-style: normal;
  font-weight: 400;
}
caption,
th {
  text-align: left;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: 400;
}
q:after,
q:before {
  content: "";
}
abbr,
acronym {
  border: 0;
  font-variant: normal;
}
sup {
  vertical-align: text-top;
}
sub {
  vertical-align: text-bottom;
}
input,
select,
textarea {
  font-family: inherit;
  font-weight: inherit;
  font-size: 100%;
}
legend {
  color: #000;
}
abbr,
address,
article,
aside,
audio,
b,
blockquote,
body,
canvas,
caption,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
p,
pre,
q,
samp,
section,
small,
span,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
ul,
var,
video {
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: 0 0;
  margin: 0;
  padding: 0;
}
body {
  line-height: 1;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
blockquote,
q {
  quotes: none;
}
blockquote:after,
blockquote:before,
q:after,
q:before {
  content: none;
}
a {
  font-size: 100%;
  vertical-align: baseline;
  background: 0 0;
  margin: 0;
  padding: 0;
}
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: 700;
}
del {
  text-decoration: line-through;
}
abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}
input,
select {
  vertical-align: middle;
}
input[type="submit"] {
  cursor: pointer;
}
.cf:after,
.cf:before {
  content: "";
  display: block;
}
.cf:after {
  clear: both;
}
.cf {
  zoom: 1;
}
nav ul,
ol,
ul {
  list-style: none;
}
*,
::after,
::before {
  box-sizing: border-box;
}
.clearfix:after,
.clearfix:before,
.row:after,
.row:before {
  display: inline;
  content: " ";
}
a,
button,
input,
select,
textarea {
  border: 0;
  outline: 0 !important;
}
button {
  font-size: 100%;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  @include prefix(transition, $ts-fast, ("webkit", "ms", "moz"));
}
strong {
  font-weight: $fw-bold;
}
img {
  height: auto;
  max-width: $unit100p;
}

// Project

body {
  position: relative;
  color: $dark;
  font-family: $ff-main;
  font-size: $fs-s;
}

a,
button {
  color: $primary;
  text-decoration: none;
  cursor: pointer;
  @include prefix(transition, color $ts-fast, ("webkit", "ms", "moz"));

  &:hover {
    text-decoration: none;
  }
}

strong {
  font-weight: $fw-semibold;
}
