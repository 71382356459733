// vars

$filter-wrap-width: 360px;
$filter-wrap-hg: 480px;
$filter-wrap-hg-tb: 400px;
$filter-wrap-hg-sm-at: calc(#{$v100} - 60px - 47px - 56px);
$filter-wrap-hg-sm: calc(#{$v100} - 60px - 56px);
$filter-index-sm: 40;
$filter-dd-shadow: 0px 15px 20px 0 rgba(29, 29, 29, 0.2);

// styles

.e-filter {
  background-color: $white;
  &-dd.open {
    z-index: 8;
  }
  &__submit {
    .btn {
      width: $unit100p;
    }
  }

  &-wrapper {
    overflow: auto;
  }

  @include respond-above(tb) {
    &-wrapper {
      max-height: $filter-wrap-hg;

      /* styling scrollbar in firefox */
      scrollbar-width: thin;
      scrollbar-color: $dark10 transparent;
    }

    /* styling scrollbar */
    &-wrapper::-webkit-scrollbar {
      width: $ls-extra;
      height: $ls-extra;
    }

    &-wrapper::-webkit-scrollbar-track {
      background: none;
    }

    &-wrapper::-webkit-scrollbar-thumb {
      background-color: $dark10;
      border: 0;
      @include prefix(border-radius, 0, ("webkit", "ms", "moz"));
    }
  }

  @include respond-between(md, tb) {
    &-wrapper {
      max-height: $filter-wrap-hg-tb;
    }
  }

  @include respond-between(sm, md) {
    &-wrapper {
      max-height: $filter-wrap-hg;
    }
  }

  @include respond-above(sm) {
    display: none;
    position: absolute;
    top: -$v-unit-xs;
    left: -$ls-small;
    width: $filter-wrap-width;
    padding-top: $unit40;
    border: $border-xs $dark10;
    @include prefix(border-radius, $v-unit-xs, ("webkit", "ms", "moz"));
    @include prefix(box-shadow, $filter-dd-shadow, ("webkit", "ms", "moz"));
    overflow: hidden;

    &.show {
      display: block;
    }
  }

  @include respond-below(sm) {
    @include full-fixed;
    z-index: $filter-index-sm;
    @include prefix(transform, translateX($unit100p), ("webkit", "ms", "moz"));
    background-color: $gray;
    @include transition(transform $ts-soft ease-in-out);

    &-wrapper {
      height: $filter-wrap-hg-sm;
    }

    &--author {
      .e-filter-wrapper {
        height: $filter-wrap-hg-sm-at;
      }
    }
    &__submit {
      .btn {
        position: fixed;
        bottom: 0;
        left: 0;
      }
    }
    &.open {
      @include prefix(transform, translateX(0), ("webkit", "ms", "moz"));
    }
  }
}
