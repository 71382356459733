// vars



// styles

.e-info {
	@include display-flex;
	@include align-items(center);
	border: $border-sm $white10;
	border-radius: $v-unit;
	font-weight: $fw-semibold;

	&__question,
	&__answer {
		line-height: $lh-big;
	}

	&__question {
		color: $white;
	}

	&__answer {
		color: $primary;
		cursor: pointer;
	}

	&:not(.e-info--alt) {
		padding: $unit24 $h-unit;

		&:before {
			content: url(#{$backgrounds}/ico_info.svg);
			margin-right: $h-unit;
		}
	}

	&--alt {
		padding: $v-unit-l $unit24;
		text-align: center;

		.e-info__wrapper {
			width: $unit100p;
		}

		.e-info__question,
		.e-info__answer {
			font-size: $fs-l;
		}
	}
}