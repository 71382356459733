$unit-height: 3px;
$background-color: #fff;
$unit-opacity: 0.4;

.progress-bar {
  display: flex;
  gap: 5px;
  &__container {
    flex-basis: 5px;
    flex: 1 1;
    height: $unit-height;
    background-color: rgba($color: #fff, $alpha: 0.4);
    height: 3px;
    &--unit {
      height: 100%;
      background-color: #FFF;
      border-radius: inherit;
      text-align: right;
    }
    .unit-filled {
      width: 100%;
    }
    .unit-empty {
      width: 0%;
    }
  }
}
