// styles

.b-starred {
  figure {
    @include flex-center;
    background-color: $gray;
    border: $border-xs $black5;
    @include prefix(border-radius, $v-unit, ("webkit", "ms", "moz", "o"));
    overflow: hidden;
    aspect-ratio: auto;
  }

  .c-pretitle {
    margin-bottom: $v-unit-xs;
  }

  .c-title {
    line-height: $lh-standard;
  }

   .c-content p em{
     font-style: italic;
   }

  .c-content,
  &__status {
    margin-top: $v-unit-l;
    color: $dark60;
    line-height: $lh-medium;
  }

  &__status {
    li {
      @include display-flex;

      @include before() {
        @include total-size($unit12);
        margin-top: $ls-extra;
        margin-right: $unit12;
        border: $border-sm $black5;
        @include prefix(border-radius, $unit50p, ("webkit", "ms", "moz", "o"));
        background-color: $black-l;
      }

      &:not(:last-child) {
        margin-bottom: $unit24;
      }

      &.without-bullets {
        &:before {
          display: none;
        }
      }

      &.approved {
        &:before {
          background-color: $primary;
        }
      }

      &.processing {
        &:before {
          background-color: $processing;
        }
      }

      &.not-approved {
        &:before {
          background-color: $not-approved;
        }
      }

      &.rejected {
        &:before {
          background-color: $rejected;
        }
      }
    }

    strong {
      color: $dark;
    }
  }

    &--black {
      background-color: $space;
      padding: 20px;
      margin-bottom: 0px !important;
      
      .c-title{
        color: $white;
      }
      .c-content *{
        color: $white;
      }
  }

  @include respond-above(md) {
    &:not(.b-starred--alt) {
      .b-starred__left {
        @include grid-column(1, 6);
      }
    }

    &--alt {
      .b-starred__right {
        @include grid-column(8, -1);
      }
    }
  }

  @include respond-between(sm, md) {
    &:not(.b-starred--alt) {
      .b-starred__left {
        @include grid-column(1, 7);
      }
    }

    &--alt {
      .b-starred__right {
        @include grid-column(7, -1);
      }
    }
  }

  @include respond-above(sm) {
    padding-top: $v-unit-xl;

    &:not(.b-starred--alt) {
      padding-bottom: $v-unit-xl;

      .b-starred__left {
        @include align-self(center);
      }

      .b-starred__right {
        @include grid-column(7, -1);
      }
    }

    &--alt {
      padding-bottom: $v-unit-xxxl;

      .b-starred__left {
        @include grid-column(1, 7);
      }

      .b-starred__right {
        @include align-self(center);
      }
    }
  }

  @include respond-below(sm) {
    .grid {
      @include display-flex;
      @include flex-direction-column;
      flex-direction: column-reverse;
    }

    figure {
      max-width: 85vw;
      margin: 30px auto 10px;
    }

    &:not(.b-starred--alt) {
      margin-top: $v-unit-xl;
      margin-bottom: $unit40;

      .b-starred__left {
        margin-top: $unit40;
      }

      .b-starred__right {
        @include flex-order(-1);
      }
    }

    &--alt {
      margin-top: $unit40;
      margin-bottom: $v-unit-xl;

      .b-starred__right {
        margin-top: $unit40;
      }
    }
  }
}
