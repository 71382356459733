// vars
$header-height : 56px;

// styles

.b-search-lite {
  &__input-wrapper {
    position: relative;

    &:before,
    #clearSearch {
      position: absolute;
      top: $search-input-w-bf-top;
      @include prefix(
        transform,
        translateY(-$unit50p),
        ("webkit", "ms", "moz")
      );
    }

    &:before {
      content: url(#{$backgrounds}/ico_search.svg);
      left: $h-unit;
    }

    input {
      caret-color: $primary;
      font-weight: $fw-semibold;
    }

    #clearSearch {
      content: url(#{$content}/clear.svg);
      right: 0;
      cursor: pointer;
    }
  }

  @include respond-above(sm) {
    padding-top: $v-unit-xl;

    &__input-wrapper {
      input {
        height: $v-unit-xl;
        padding-top: $v-unit;
        padding-bottom: $v-unit;
        padding-left: $search-input-pl;
        padding-right: $h-unit;
        font-size: $fs-xxl;
      }
    }

    &__btn {
      @include hideit();
    }
  }

  @include respond-below(sm) {
    margin-top: $header-height;
    z-index: 40;
    background-color: $white;

    body.menu-launched & {
      z-index: 20;
    }

    &__input-wrapper {
      input {
        height: $unit56;
        padding-left: $unit56;
      }
    }

    &__btn {
      position: fixed;
      bottom: 0;
      left: 0;
      width: $unit100p;
      padding: $v-unit-m $h-unit-m;
    }
  }
}
