// fields

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="password"],
input[type="search"],
textarea {
  display: block;
  width: $unit100p;
  background-color: transparent;
  @include placeholder-color($gray);
  line-height: $lh-reset;
  @include prefix(transition, $ts-fast, ("webkit", "ms", "moz"));

  @include respond-below(tb) {
    @include prefix(appearance, none!important, ("webkit", "ms", "moz"));
  }
}
label.input--checkbox,
input[type="checkbox"] {
  display: inline-block;
  width: $unit24;
  height: $unit24;
  margin-right: $h-unit-xs;
  @include prefix(appearance, none!important, ("webkit", "ms", "moz"));
  background-color: $gray;
  @include prefix(border-radius, 0!important, ("webkit", "ms", "moz"));
  cursor: pointer;
  @include transition(background-color $ts-fast);

  &.active,
  &:checked {
    background-color: $primary;
    background-image: url(#{$backgrounds}/checked.svg);
    background-position: center;
    background-repeat: no-repeat;
  }
}

input[type="submit"] {
  @include justify-content(center);
  @include prefix(transition, $ts-fast, ("webkit", "ms", "moz"));

  &:disabled {
    @include prefix(opacity, 0.5, ("webkit", "ms", "moz", "o"));
    cursor: not-allowed;
  }

  @include respond-below(tb) {
    @include prefix(appearance, none!important, ("webkit", "ms", "moz"));
  }
}

select {
  display: inline-block;
  padding: $v-unit $h-unit;
  @include prefix(appearance, none, ("webkit", "ms", "moz"));
  // background-image: url(#{$backgrounds}/select.png);
  background-position: calc(#{$unit100p} - #{$h-unit}) $unit50p;
  background-repeat: no-repeat;
}
