// vars

// styles

.e-cats {
  @include display-flex;
  margin-top: $v-unit;

  & > *:not(:last-child) {
    margin-right: $h-unit-xs;
  }
}

.e-cat {
  @include flex-center;
  height: $v-unit-l;
  padding: $v-unit-xs $h-unit;
  background-color: $gray;
  border: $border-xs $black5;
  @include prefix(border-radius, $v-unit, ("webkit", "ms", "moz"));
  color: $dark;
  font-weight: $fw-medium;
  line-height: $lh-small;
  font-size: $fs-xs;

  i {
    margin-right: $h-unit-xs;
  }

  &--more {
    width: $h-unit-l;
  }
}

.single .e-cats {
  flex-wrap: wrap;
  & > *:not(:last-child) {
    margin-bottom: $h-unit-xs;
  }
}
