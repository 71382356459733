// vars

// styles

.card-info {
  padding: 40px;
  background-color: $space;
  @include prefix(border-radius, $v-unit, ("webkit", "ms", "moz"));

  .c-title {
    color: $white;
  }

  &__text,
  &__links {
    margin-top: $v-unit-l;
  }

  &__text {
    color: $white60;
    font-size: $fs-l;
    line-height: $lh-standard;
  }

  &__links {
    li {
      font-size: $fs-m;
      line-height: $lh-small;
      &:not(:last-child) {
        margin-bottom: $v-unit;
      }
      button {
        font-family: $ff-main;
        text-align: left;
      }
    }
  }
}
