// vars

$b-menu-height-sm: calc(#{$v100} - #{$header-height-sm});
$logoNewtralWidth: 135px;
$logoSponsorWidth: 70px;

// styles

.mobile-nav {
  @include respond-above(md) {
    @include hideit();
  }

  @include respond-below(md) {
    @include fixed;
    display: flex;
    flex-direction: column;
    top: $header-height-sm;
    @include prefix(transform, translatex($unit100p), ('webkit', 'ms', 'moz'));
    height: $b-menu-height-sm;
    overflow: auto;
    z-index: 20;
    background-color: $space;
    @include transition(transform $ts-soft ease-in-out);

    &__menu {
      margin-top: $unit48;
      flex: 1 1 auto;

      & > ul {
        padding-left: $unit24;
        padding-right: $unit24;
      }

      a,
      button {
        color: $white;
      }

      & > ul > li {
        & > a,
        & > button {
          @include flex-between;
          @include align-items(center);
          padding-top: $v-unit-m;
          padding-bottom: $v-unit-m;
          font-size: $fs-xl;
          font-weight: $fw-semibold;
        }

        &.menu-item-has-children {
          & > button {
            width: 100%;
            @include flex-between;
            @include align-items(center);
            padding-top: $v-unit-m;
            padding-bottom: $v-unit-m;
            font-size: $fs-xl;
            font-weight: $fw-semibold;
            &:after {
              content: url(#{$backgrounds}/dd_white.svg);
            }

            &.open {
              &:after {
                @include prefix(transform, rotate($rotate-hor), ('webkit', 'ms', 'moz'));
              }
            }
          }

          .sub-menu {
            max-height: 100vh;
            overflow: hidden;
            pointer-events: initial;

            @include transition(max-height $ts-fast);
            &:not(.active) {
              pointer-events: none;
              max-height: 0;
            }
            li {
              a,
              button {
                font-size: $fs-m;
                line-height: $lh-medium;
              }

              &:not(:last-child) {
                margin-bottom: $v-unit;
              }
            }
          }
        }

        &:not(:last-child) {
          border-bottom: $border-xs $white10;
        }
      }
    }
    &__logo {
      position: relative;
      background-color: $white10;
      color: $white60;
      border-radius: $h-unit;
      padding: $unit24;
      margin: $unit24;
      line-height: 37px;
      > img {
        width: $logoNewtralWidth;
        margin-top: 7px;
      }
      > span {
        display: block;
      }
      > a {
        color: $white;
      }
      &__newtral {
        > img {
          width: $logoNewtralWidth;
        }
      }
      &__sponsor {
        > img {
          width: $logoSponsorWidth;
        }
      }
    }

    &__logo::after {
      display: inline-block;
      content: url(#{$backgrounds}/arrow_left.svg);
      transform: rotate(180deg);
      position: absolute;
      right: $unit24;
      bottom: 35px;
    }

    .menu-launched & {
      @include prefix(transform, translateX(0), ('webkit', 'ms', 'moz'));
    }
  }
}
