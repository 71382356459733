// vars

$modal-content-hor-padd: 128px;
$modal-author-pt-xs: 75px;
$modal-xs-gradient: 360deg, rgba($white, 0.9) 0%, rgba($white, 0.75) $unit50p,
  rgba($white, 0.3) $unit75p, rgba($white, 0) $unit100p;
$modal-close-left: 94.6%;
$modal-close-left-mb: calc(#{$unit100p} - #{$unit40});

// styles
.modal {
  display: none;
  @include full-fixed;
  z-index: 40;
  background-color: $dark60;

  &__inner {
    position: absolute;
    max-height: $unit100p;
    overflow: auto;
    background-color: $white;
    height: 0;
    opacity: 0;
    @include prefix(opacity, $opacity-none, ("webkit", "ms", "moz"));
  }

  &__inner.active {
    height: auto;
    position: relative;
    @include prefix(opacity, $opacity-full, ("webkit", "ms", "moz"));
  }

  .modalClose {
    content: url(#{$backgrounds}/modal_close.svg);
    position: sticky;
    top: $v-unit;
    left: $modal-close-left;
    cursor: pointer;
  }

  &__header {
    .c-title {
      color: $dark;
      line-height: $lh-small;
    }
  }

  &__subtitle {
    color: $dark60;
    line-height: $lh-medium;
  }

  &__content {
    border-top: $border-xs $dark10;

    & .modal__notice:only-child {
      margin-top: 0;
    }
  }

  &__content-title {
    font-weight: $fw-semibold;
    line-height: $lh-standard;
    &--mgBottom {
      margin-bottom: $v-unit;
    }
  }

  &__content-list {
    margin-top: $v-unit;

    li {
      @include display-flex;
      @include align-items(center);
      margin-bottom: $v-unit;

      i {
        @include flex-center;
        width: $h-unit-m;
        height: $h-unit-m;
        margin-right: $h-unit-xs;
      }
    }
  }

  .c-content {
    color: $dark60;
    line-height: $lh-big;
  }

  &__notice {
    @include display-flex;
    position: relative;
    margin-top: $v-unit;
    padding: $unit12;
    padding-right: $unit48;
    @include prefix(border-radius, $v-unit-xs, ("webkit", "ms", "moz"));
    line-height: $lh-standard;

    i {
      @include total-size($v-unit-m);
      margin-right: $h-unit-xs;
    }

    span {
      padding-top: $ls-medium;
    }

    &--wrong {
      background-color: $wrong-soft;
      color: $wrong;
    }

    &--success {
      background-color: $success-soft;
      color: $success;
    }

    &--info {
      background-color: $gray;
      border: $border-xs $black5;
      color: $dark60;
    }

    &__step {
      position: absolute;
      top: $unit50p;
      right: $h-unit;
      @include prefix(
        transform,
        translateY(-$unit50p),
        ("webkit", "ms", "moz")
      );
    }
  }

  &:not(.modal--authors) {
    .modal__header {
      .c-title {
        text-align: center;
      }
    }
  }

  &.modal--authors {
    .modal__header {
      .c-title {
        font-weight: $fw-bold;
      }
    }
  }

  .c-content .modal__notice + li,
  &__notice + .modal__content-title,
  .c-content .modal__notice + p {
    margin-top: $v-unit;
  }

  @include respond-above(tb) {
    padding-bottom: 180px;

    &__inner {
      width: $unit66p;
      max-width: 745px;
      margin-top: $v-unit-xl;

      /* styling scrollbar in firefox */
      scrollbar-width: thin;
      scrollbar-color: $dark10 transparent;
    }

    /* styling scrollbar */
    &__inner::-webkit-scrollbar {
      width: $h-unit-xs;
      height: $v-unit-xs;
    }

    &__inner::-webkit-scrollbar-track {
      background: none;
    }

    &__inner::-webkit-scrollbar-thumb {
      background-color: $dark10;
      border: 0;
      @include prefix(border-radius, $v-unit, ("webkit", "ms", "moz"));
    }

    &__header {
      padding-top: $unit48;
      padding-bottom: $v-unit-l;
      padding-left: $h-unit-l;
      padding-right: $h-unit-l;

      &.title_main_modal {
        padding-left: $h-unit-xl;
        padding-right: $h-unit-xl;
      }
    }

    &__content {
      padding-left: $modal-content-hor-padd;
      padding-right: $modal-content-hor-padd;
    }

    &__content-title {
      margin-bottom: $v-unit-l;
      font-size: $fs-xl;
    }

    &.modal--authors {
      .modal__header {
        padding-left: $modal-content-hor-padd;
        padding-right: $modal-content-hor-padd;
      }
    }
  }

  @include respond-between(md, tb) {
    overflow: hidden;
    padding-bottom: $v-unit-xxl;
  }

  @include respond-above(md) {
    &__inner {
      width: $unit66p;
      max-width: 745px;
    }
  }

  @include respond-between(sm, tb) {
    &__inner {
      margin-bottom: $v-unit-xxl;
    }

    &__header,
    &__content {
      padding-left: $h-unit-xxl;
      padding-right: $h-unit-xxl;
    }

    &__header {
      padding-top: $v-unit-l;
      padding-bottom: $v-unit-l;
    }
  }

  @include respond-between(sm, md) {
    &__inner {
      width: $unit75p;
      height: 80%;
    }
  }

  @include respond-above(sm) {
    &__mobile-nav {
      @include hideit();
    }

    &__inner {
      max-height: calc(100vh - #{$v-unit-xxxl});
      margin-top: $v-unit-xl;
      margin-left: auto;
      margin-right: auto;
      @include prefix(border-radius, $v-unit, ("webkit", "ms", "moz"));
    }

    &__subtitle {
      margin-top: $v-unit;
      font-size: $fs-l;
    }

    &:not(.modal--authors) {
      .modal__content {
        padding-top: $v-unit-l;
        padding-bottom: $v-unit-l;
      }
    }

    &.modal--authors {
      .modal__inner {
        position: relative;
        .modal__content:last-child {
          padding-bottom: $h-unit-xl;
        }
        // &:after {
        // 	content: "";
        // 	position: absolute;
        // 	bottom: 0;
        // 	left: 0;
        // 	width: $unit100p;
        // 	height: $v-unit-xxxl;
        // 	background: -webkit-linear-gradient($modal-xs-gradient);
        // 	background: -moz-linear-gradient($modal-xs-gradient);
        // 	background: -o-linear-gradient($modal-xs-gradient);
        // 	background: linear-gradient($modal-xs-gradient);
        // }
      }

      .modal__wrapper {
        max-height: $unit100p;
        overflow: auto;
      }

      .modal__content {
        padding-top: $v-unit;
        padding-bottom: $v-unit;
      }
    }
  }

  @include respond-below(sm) {
    &:not(.modal--authors) {
      .modalClose {
        &:not(.close_main_modal) {
          @include hideit();
        }
      }

      .modal__mobile-nav {
        position: sticky;
        top: 0;
        background: $white;
        z-index: 30;
        padding: $v-unit $unit24;
        border-bottom: $border-xs $dark10;

        span {
          @include display-flex;
          @include align-items(center);
          font-size: $fs-s;
          font-weight: $fw-semibold;

          &:before {
            content: url(#{$backgrounds}/mobile_nav.svg);
            margin-right: $unit24;
          }
        }
      }

      .modal__header,
      .modal__content {
        padding: $v-unit-l $h-unit-l;
      }

      .modal__content-title {
        font-size: $fs-xl;
      }
    }

    &--authors {
      padding-top: $modal-author-pt-xs;
      padding-bottom: $v-unit;
      padding-left: $h-unit;
      padding-right: $h-unit;

      .modalClose {
        top: 0;
        left: $modal-close-left-mb;
        @include prefix(opacity, $opacity-medium, ("webkit", "ms", "moz"));
      }

      .modal__inner {
        position: relative;
        max-height: $unit100p;
        padding-top: $v-unit;
        background-color: $white;
        @include prefix(border-radius, $v-unit, ("webkit", "ms", "moz"));
      }

      .modal__wrapper {
        position: relative;
        max-height: 485px;
        padding-top: $unit24;
        padding-bottom: $unit24;
        background-color: $white;
        overflow: auto;
      }

      .modal__header,
      .modal__content {
        padding-left: $h-unit;
        padding-right: $h-unit;
      }

      .modal__header {
        padding-bottom: $v-unit;
      }

      .modal__content {
        padding-top: $v-unit;
        padding-bottom: $v-unit;
      }
    }

    &__subtitle {
      margin-top: $ls-extra;
    }
  }
}
