// vars

// styles

.e-filter__group {
  dt,
  dd {
    padding-left: $h-unit-m;
    padding-right: $unit48;
  }

  dt {
    padding-top: $v-unit-xs;
    padding-bottom: $v-unit-xs;
    background-color: $gray;
    color: $dark60;
    line-height: $lh-small;
  }

  dd {
    @include display-flex;
    @include align-items(center);
    position: relative;
    padding-top: $v-unit-m;
    padding-bottom: $v-unit-m;

    i {
      @include total-size($unit40);
      margin-right: $unit12;
      overflow: hidden;
      @include prefix(border-radius, $unit50p, ("webkit", "ms", "moz"));
    }

    .e-filter__group-checkbox {
      @include absolute;
      margin-right: 0;
      @include prefix(appearance, none, ("webkit", "ms", "moz"));
      background-color: transparent;
      border: 0;
      cursor: pointer;

      &:after {
        position: absolute;
        top: $unit50p;
        right: $h-unit;
        @include prefix(
          transform,
          translateY(-$unit50p),
          ("webkit", "ms", "moz")
        );
        content: url(#{$backgrounds}/filter_check.svg);
        @include prefix(opacity, $opacity-none, ("webkit", "ms", "moz"));
        @include transition(opacity $ts-fast);
      }
      &.checked:after {
        @include prefix(opacity, $opacity-full, ("webkit", "ms", "moz"));
      }

      input[type="checkbox"] {
        position: absolute;
        height: 0;
        width: 0;
        @include prefix(opacity, $opacity-none, ("webkit", "ms", "moz"));
      }
    }
  }

  &-inner {
    & > span {
      display: block;
      line-height: $lh-small;
    }

    .name {
      color: $dark;
    }

    .subtitle {
      margin-top: $ls-extra;
      color: $dark60;
    }
  }

  @include respond-above(sm) {
    dt {
      border-top: $border-xs $dark10;
    }

    dd {
      border-top: $border-xs $dark10;
    }
  }

  @include respond-below(sm) {
    background-color: $white;
    border-bottom: $border-xs $dark10;

    &-inner{
      @include display-flex;
      @include align-items(center);
      img + span{
        margin-left: $v-unit-xs;
      }
    }

    dd:not(:first-child) {
      border-top: $border-xs $dark10;
    }
  }
}
