// vars



// styles

.block {

	@include respond-above(tb) {
    margin-top: $v-unit-xxl;
    margin-bottom: $v-unit-xxl;
  }

  @include respond-between(sm,tb) {
    margin-top: $v-unit-xl;
    margin-bottom: $v-unit-xl;
  }

  @include respond-below(sm) {
    margin-top: $unit48;
    margin-bottom: $unit48;
  }
}