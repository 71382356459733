// vars

$header-pad-ver: 72px;

// styles

.b-header {
  background-color: $gray;
  text-align: center;

  &__subtitle {
    margin-top: $v-unit-xs;
    color: $dark60;
    font-size: $fs-l;
    line-height: $lh-medium;
  }

  .c-title {
    line-height: $lh-standard;
  }

  @include respond-above(sm) {
    margin-top: $v-unit-xl;
    padding-top: $header-pad-ver;
    padding-bottom: $header-pad-ver;
  }

  @include respond-below(sm) {
    margin-top: $unit56;
    padding-top: $unit40;
    padding-bottom: $unit40;
  }
}
