// vars

$footer-padding-bottom: 40px;

// styles

.site-footer {
  border-top: $border-xs $dark10;
  background-color: #fff;

  &__menu {
    ul {
      li {
        font-size: $fs-m;
        padding: $fs-s;

        a {
          color: $dark80;
        }
      }
    }

    &--bold {
      ul {
        li {
          a {
            color: $dark;
            font-weight: $fw-semibold;
            display: block;
          }
        }
      }
    }
    &__logo {
      p {
        font-size: $fs-xs;
        font-weight: 500;
        color: $black;
        padding-bottom: $unit12;
        display: inline-block;
        width: 110px;
      }
      > img {
        width: 110px;
        display: flex;
        margin-bottom: $unit12;
      }

      &__sponsor {
        > img {
          width: 70px;
        }
      }
      &__europe {
        margin-top: 10px;
        &__text {
          font-size: $fs-s !important;
          font-weight: normal !important;
          display: block !important;
        }
        > img {
          width: 70px;
        }
      }
      &__collaboration {
        &__text {
          font-size: $fs-s !important;
          font-weight: normal !important;
          display: block !important;
        }
        > img {
          width: 65px;
          margin-bottom: 0;
        }
      }
    }
  }

  @include respond-above(md) {
    &__menu {
      ul {
        li:not(:last-child) {
          margin-right: $h-unit;
        }
      }
    }
  }

  @include respond-between(sm, md) {
    &__menu {
      ul {
        li:not(:last-child) {
          margin-right: $h-unit-m;
        }
      }
    }
  }

  @include respond-above(md) {
    padding-top: $unit24;
    padding-bottom: $footer-padding-bottom;

    .container {
      @include flex-between;
    }

    &__menu {
      ul {
        @include display-flex;
      }
    }
  }

  @include respond-below(md) {
    padding: $v-unit-l $h-unit;

    &__menu {
      ul {
        li {
          a {
            line-height: $lh-medium;
          }
          img {
            max-width: initial;
          }

          &:not(:last-child) {
            margin-bottom: $v-unit;
          }
        }
      }

      &:not(:last-child) {
        margin-bottom: $v-unit;
      }
      &__logo {
        &__sponsor {
          > p {
            text-align: left;
          }
        }
      }
    }
  }
}
