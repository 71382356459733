// vars

// styles

.card {
  position: relative;
  background-color: $white;
  @include prefix(border-radius, $v-unit, ("webkit", "ms", "moz"));

  &__bottom,
  %ue {
    border-top: $border-xs $dark10;

    a {
      @include flex-between;
      @include align-items(center);

      &:after {
        content: url(#{$backgrounds}/ico_download.svg);
      }
    }

    a:not(:last-child) {
      margin-bottom: $v-unit;
    }

    .card-law__share+& {
      margin-top: $unit24;
    }

    & .e-author {
      flex-wrap: nowrap;
    }
  }

  @include respond-above(sm) {

    &__bottom,
    %ue {
      padding: $v-unit $h-unit-l;
    }
  }

  @include respond-below(sm) {

    &__bottom,
    %ue {
      padding-top: $v-unit;
      padding-left: $h-unit;
      padding-right: $h-unit;
    }

    &__related {
      &>* {
        &:not(:last-child) {
          border-bottom: $border-xs $dark10;
        }
      }

      &__title {
        margin-top: $v-unit-xs;
        margin-bottom: $v-unit-xs;
        color: $dark60;
        font-size: $fs-m;
        line-height: $lh-standard;
      }
    }
  }
}

.ue {
  @extend %ue;

  &__box {
    @include display-flex;
    @include align-items(center);

    &__img {
      @include display-flex;
      @include justify-content(center);
      @include align-items(center);
      margin-right: $v-unit-xs;

      img {
        width: 30px;
      }
    }

  }
}