//
// e-content
//

.c-content {
  font-size: $fs-s;
  line-height: $lh-medium;

  &.parsed-text{
    strong{
      font-style: inherit;
    }
    i{
      font-style: italic;
    }
  }

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: $fw-semibold;
    line-height: $lh-medium;
  }

  ul,
  ol {
    li {
      list-style-position: inside;
    }
  }

  ul {
    li {
      list-style-type: disc;
    }
  }

  ol {
    li {
      list-style-type: decimal;
    }
  }

  iframe {
    max-width: $unit100p !important;
    width: 100% !important;
  }

  @include respond-above(sm) {
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    ul,
    ol {
      margin-bottom: $v-unit-m;
    }

    & > img,
    .wp-caption {
      margin-top: $v-unit-xl;
      margin-bottom: $v-unit-xl;
    }

    h1,
    h2 {
      font-size: $fs-xl;
    }

    h3,
    h4 {
      font-size: $fs-l;
    }

    h5,
    h6 {
      font-size: $fs-m;
    }

    &.c-content--two-cols {
      @include prefix(column-count, 2, ("webkit", "ms", "moz"));
      @include prefix(column-gap, $h-unit-l, ("webkit", "ms", "moz"));

      p {
        break-inside: avoid-column;
      }
    }

    &.c-content--centered {
      text-align: center;
    }

    &--half {
      width: $unit60p;
    }
  }

  @include respond-below(sm) {
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    ul,
    ol {
      margin-bottom: $v-unit;
    }

    & > img,
    .wp-caption {
      margin-top: $v-unit-l;
      margin-bottom: $v-unit-l;
    }

    h1,
    h2 {
      font-size: $fs-xxl;
    }

    h3,
    h4 {
      font-size: $fs-xl;
    }

    h5,
    h6 {
      font-size: $fs-l;
    }
  }
}
