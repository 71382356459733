// vars



// styles

.b-search__tabs {
	@include display-flex;

	@include respond-below(sm) {
		border-bottom: $border-xs $black5;
	}
}

.b-search__tab {
	padding-top: $v-unit;
	padding-bottom: $v-unit-xs;
	border-bottom: $border-sm transparent;
	cursor: pointer;
	line-height: $lh-big;
	font-weight: $fw-medium;
	text-align: center;
	@include prefix(opacity, $search-tb-opacity, ('webkit', 'ms', 'moz'));
	@include transition(opacity $ts-fast, border-color $ts-fast);

	&.active {
		border-color: $black;
		@include prefix(opacity, $opacity-full, ('webkit', 'ms', 'moz'));
	}

	&--starred {
		color: $dark!important;
	}

	&--content {
		display: none;

		&.active {
			display: block;
		}
	}

	@include respond-above(sm) {
		@include flex(0,0,$unit25p);

		&--starred {
			padding: $unit12 $unit24;
			background-color: $gray;
		}
	}

	@include respond-below(sm) {
		padding-left: $h-unit-m;
		padding-right: $h-unit-m;

		&--starred {
			padding: $v-unit $h-unit;
		}
	}
}