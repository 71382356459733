// vars

$pp: rgba(63, 123, 195, 0.8);
$bildu: rgba(172, 209, 72, 0.8);
$psoe: rgba(212, 81, 58, 0.8);
$ciudadanos: rgba(239, 192, 89, 0.8);

// styles

.b-squares {
  &__title {
    color: $dark;
    margin-top: $h-unit-m;
    font-size: $fs-xxxl;
    font-weight: $fw-medium;
  }

  &__subtitle {
    color: $dark60;
    font-size: $fs-xs;
    line-height: $lh-read;
  }

  &__inner {
    margin-top: $v-unit;
  }

  &__inner-header {
    & > * {
      color: $dark;
      line-height: $lh-big;
    }
  }

  &__squares {
    @include display-flex;
    @include flex-wrap(wrap);

    & > span {
      @include total-size($v-unit-xs);
      border: 0.15px solid $white;
      box-sizing: content-box;
    }

    &.plural {
      & > span {
        background-color: $black80;
      }
    }

    &.pp {
      & > span {
        background-color: $pp;
      }
    }

    &.bildu {
      & > span {
        background-color: $bildu;
      }
    }

    &.psoe {
      & > span {
        background-color: $psoe;
      }
    }

    &.ciudadanos {
      & > span {
        background-color: $ciudadanos;
      }
    }
  }

  .b-single & {
    padding-top: $v-unit;
    padding-bottom: $unit24;
  }
}
