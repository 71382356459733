// vars



// style

.e-social {
  @include display-flex;
  @include align-items(center);

  a {

    @include link() {
      @include flex-center;

      svg {

        g {
          @include prefix(transition, fill $ts-fast, ('webkit', 'ms', 'moz'));
        }
      }
    }
  }

  @include respond-above(sm) {

    li:not(:last-child) {
      margin-right: $h-unit;
    }
  }

  @include respond-below(sm) {

    li:not(:last-child) {
      margin-right: $h-unit-xs;
    }
  }
}