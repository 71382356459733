// vars

// styles

.e-filter-mb {
  @include respond-above(sm) {
    @include hideit();
  }

  @include respond-below(sm) {
    @include full-fixed;
    @include prefix(transform, translateX($unit100p), ("webkit", "ms", "moz"));
    z-index: 40;
    background-color: $gray;
    @include transition(transform $ts-soft ease-in-out);

    &__filter-open,
    &__cats {
      @include display-flex;
      background-color: $white;
    }

    &__filter-open {
      @include justify-content(space-between);
      padding: $v-unit $h-unit;
      border-bottom: $border-xs $dark10;
      font-size: $fs-m;
      font-weight: $fw-semibold;

      .e-filter-mb__filter-subtitle {
        font-family: $ff-main;
        font-style: normal;
        font-weight: $fw-normal;
        font-size: $fs-s;
        margin-top: $h-unit;
        color: $dark60;
        line-height: $h-unit;
      }
      .e-filter-mb__filter-open--text {
        @include display-flex;
        flex-direction: column;
      }

      .e-filter-mb__filter-open-inner {
        padding-right: $h-unit-l;
      }

      .e-filter-mb__count {
        display: block;
        color: $dark60;
        font-size: $fs-s;
        font-weight: $fw-normal;
      }

      &:after {
        content: url(#{$backgrounds}/mobile_menu.svg);
        height: $v-unit;
      }

      &.cats {
        border-bottom: 0;

        span {
          @include display-flex;
          @include align-items(center);
          height: $v-unit;
          color: $dark60;
          font-weight: $fw-normal;

          i {
            margin-top: $ls-big;
            margin-left: $unit12;
          }
        }
        & ~ .e-filter-mb__filter-subtitle {
          display: flex;
          background-color: $white;
          font-family: $ff-main;
          font-style: normal;
          font-weight: $fw-normal;
          font-size: $fs-s;
          color: $dark60;
          line-height: $h-unit;
          border-bottom: $border-xs $dark10;
          padding: $v-unit $h-unit;
        }

        &:after {
          @include hideit();
        }
      }
    }

    &__cats {
      @include grid-wrapper(repeat(3, 1fr), $h-unit-xs);
      padding-left: $h-unit;
      padding-right: $h-unit;

      &:not(.active) {
        padding-bottom: $v-unit-xs;
        border-bottom: $border-xs $dark10;
      }
      li {
        @include flex-center;
        @include flex-direction-column;
        position: relative;
        margin-bottom: $v-unit-xs;
        padding: $v-unit $h-unit-xs;
        background-color: $gray;
        @include prefix(border-radius, $v-unit, ("webkit", "ms", "moz"));

        &.active {
          border: 2px solid #1f1f1f;
        }
        i {
          margin-bottom: $ls-extra;
        }

        span {
          font-weight: $fw-semibold;
          text-align: center
        }

        .input--checkbox {
          @include absolute;
          background-color: transparent;
          input[type="checkbox"] {
            position: absolute;
            height: 0;
            width: 0;
            @include prefix(opacity, $opacity-none, ("webkit", "ms", "moz"));
          }
        }
      }
    }

    &.open {
      @include prefix(transform, translateX(0), ("webkit", "ms", "moz"));
      overflow: hidden;
    }
  }
}
