// vars



// styles

#cookie-notice {

  .cn-button {
    padding: $v-unit-xs;
    background-color: $primary;
    @include prefix(border-radius, 0, ('webkit', 'ms', 'moz', 'o'));
    color: $white;
  }
}