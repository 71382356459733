// vars

$filter-btn-pt: 10px;
$filter-btn-pb: 6px;

// styles

.e-filter-btn {
  padding: $filter-btn-pt $h-unit-l $filter-btn-pb $h-unit-l;
  background-color: $white;
  @include display-flex;
  @include align-items(center);
  @include justify-content(center);
  
  color: $black;
  font-family: $ff-main;
  font-size: $fs-s;
  font-weight: $fw-medium;
  white-space: nowrap;
  
  border: $border-xs $dark10;
  @include prefix(appearance, none, ("webkit", "ms", "moz"));
  @include prefix(border-radius, $v-unit-m, ("webkit", "ms", "moz"));

  img{
    margin-right: $v-unit-xs;
    height: $h-unit-m;
  }

  &.active {
    border-color: $primary;
  }
}
