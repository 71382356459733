$stories-width: 382px;
$stories-height: 680px;

.story-container {
  width: 100%;
  display: flex;
  height: 100%;
  z-index: 1;
  @include flex-direction-column;
  justify-content: space-between;
  position: relative;
  @include respond-above(xs) {
    width: $stories-width * 0.65;
    height: $stories-height * 0.65;
    border-radius: 16px;
    min-width: 290px;
  }
  @include respond-above(pw) {
    width: $stories-width * 0.8;
    height: $stories-height * 0.8;
  }
  @media (min-width: 1700px) {
    width: $stories-width * 0.9;
    height: $stories-height * 0.9;
  }
  &__bg {
    background-image: url(#{$backgrounds}/storie_bg.jpeg);
    background-position: $unit50p $unit50p;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    @include respond-above(xs) {
      border-radius: 16px;
    }
  }
  &__header {
    display: flex;
    margin: 15px 15px 0 15px;
    justify-content: space-between;
    visibility: visible;
    > a {
      margin-left: 10px;
      margin-top: 5px;
      @include respond-above(xs) {
        display: none;
      }
    }
  }
  &__subt {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    p {
      color: #fff;
      margin-top: 5px;
      font-family: Graphik;
      font-weight: 400;
      font-size: 10px;
    }
  }
  &__share {
    border-radius: 60px;
    border: 2px solid rgba($color: #fff, $alpha: 0.1);
    padding: 5px;
    cursor: pointer;
    img {
      position: relative;
      right: 1px;
      top: 1px;
    }
  }

  &__btn {
    width: 168px;
    border-radius: 30px;
    height: auto;
    padding: 15px 15px;
    @include respond-above(xs) {
      width: 100%;
      border-radius: 0 0 16px 16px;
      margin: 0;
    }
  }
  &__buttons {
    display: flex;
    margin: 20px 10px;
    align-items: center;
    justify-content: space-around;
    @include respond-above(xs) {
      margin: 0;
    }
  }

  &__arrows {
    &--right {
      border: 2px solid rgba($color: #fff, $alpha: 0.1);
      border-radius: 50%;
      padding: 10px;
      width: 40px;
      height: 40px;
      transform: rotate(180deg);
      order: 1;
      z-index: 1;
      @include respond-above(xs) {
        right: -50px;
        position: absolute;
      }
    }
    &--left {
      border: 2px solid rgba($color: #fff, $alpha: 0.1);
      border-radius: 50%;
      width: 40px;
      height: 40px;
      z-index: 1;
      @include respond-above(xs) {
        position: absolute;
        left: -50px;
      }
    }
  }
  &.selected {
    @include respond-above(xs) {
      transform: scale(1.05);
      transition: 0.5s transform ease-in-out;
    }
  }
  &.inactive {
    @include respond-below(xs) {
      display: none;
    }
    transform: scale(0.85);
  }
  &.non-shared {
    opacity: 0.3;
    transition: all 0.3s ease-in-out;
  }
  &.hidden {
    visibility: hidden;
  }
  &.none {
    opacity: 0;
    transition: all 0.3s ease;
  }
  &::after {
    content: '';
    position: absolute;
    background-color: black;
    bottom: -130vh;
    width: 400px;
    left: -10px;
    height: 130%;
    @include respond-above(xs) {
      bottom: -$stories-height * 0.84;
    }
    @include respond-above(pw) {
      bottom: -$stories-height * 1.04;
    }
    @media (min-width: 1700px) {
      bottom: -$stories-height * 1.17;
    }
  }
}
