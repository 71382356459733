// vars



// styles

.b-filters {
	background-color: $gray;
	border-bottom: $border-xs $dark10;

	ul {
		@include display-flex;
		

		& > *:not(:last-child) {
			margin-right: $h-unit-xs;
		}
		li {
			button{
				height: $unit100p;
			}
		} 
	}
	@include respond-above(tb) {
		
		ul {
			position: relative;
			overflow: hidden;
			padding-bottom: $unit24;
			cursor:grab;
			
			&.active {
				cursor: grabbing;
			}
		}
	}

	@include respond-below(tb) {

		.container {
			padding-left: 0;
			padding-right: 0;
		}

		ul {
			overflow-x: scroll;
			padding-left: $h-unit;
			padding-right: $h-unit;
			padding-bottom: $v-unit;
		}
	}

	@include respond-between(sm,tb) {
		padding-bottom: $v-unit-xs;
	}

	@include respond-above(sm) {
		ul {
		padding-top: $unit24;
	
	}
	}

	@include respond-below(sm) {
		padding-top: $v-unit;
	}
}