// vars

$graphic-ver-padding:		calc(#{$v-unit-xl} + #{$v-unit-xs});
$border-radius: 16px;
$highest-bar-height:200px;

// styles

.b-graphic {
	background-color: $space;

	.b-graphic__left {
		.c-title {
			color: $white;
			font-weight: $fw-bold;
			line-height: $lh-normal;
		}
	}

	a {
		display: inline-block;
		margin-top: $v-unit;
		font-size: $fs-l;
		line-height: $lh-standard;
	}

	&__wrapper{
		height: $unit100p;
		background: $white;
		border-radius: $border-radius;
		padding-top: $unit48;	
		padding-bottom: $unit48;	
		padding-left: $v-unit-l;
		padding-right: $v-unit-l;

		.c-content{
			p {
			margin-bottom: $unit24;
			font-weight: $fw-semibold;
			text-align: center;
			}
		}
		
	}
	&__bars{
		@include grid-wrapper(repeat(3, minmax(0,1fr)), $v-unit-xs);
		@include align-items(flex-end);

	
		.e-graphic__bar{
			width: $unit100p;
			background-color: $gray;
			padding:$h-unit-xs;
			@include display-flex;
			@include flex-direction-column;
			@include align-items(center);
			@include justify-content(space-between);
			text-align: center;
			
			&:last-child{
				background-color: $primary;
				height: $highest-bar-height;
			};

			.bar-icon{
				@include display-grid;
				justify-items:center;

				img {
					padding-bottom: $v-unit-xs;
				}
				.c-content{
					text-overflow: ellipsis;
					word-wrap: break-word;
					overflow: hidden;
					line-height: 1.4;
					display: -webkit-box;
					-webkit-line-clamp: 3;
					-webkit-box-orient: vertical;
				}
			}
		}

	}

	@include respond-above(md) {
		padding-top: $graphic-ver-padding;
		padding-bottom: $graphic-ver-padding;

		.container {
			@include grid-wrapper(repeat(12,1fr));
		}

		&__left {
			@include grid-column(1,6);
			padding-top: $v-unit-l;
		}

		&__right {
			@include grid-column(7,12);
		}
	}

	.card & {
		padding-top: $v-unit;
		padding-bottom: $unit24;
	}

	@include respond-between(sm,md) {
		padding-top: $v-unit-xl;
		padding-bottom: $v-unit-xl;

		.container {
			@include grid-wrapper(repeat(2,1fr), $h-unit-m);
		}
	}
		@include respond-below(md) {

		&__right {
			.b-graphic__wrapper{
			padding-top: $unit48;	
			padding-bottom: $unit48;	
			padding-left: $unit12;
			padding-right: $unit12;
			}
		}
	}

	@include respond-below(sm) {
		padding-top: $unit48;		
		padding-bottom: $unit48;

		&__right {
			margin-top: $v-unit-l;

			.b-graphic__wrapper{
			padding-top: $unit48;	
			padding-bottom: $unit48;	
			padding-left: $unit12;
			padding-right: $unit12;
			}
			.b-graphic__bars{

				.c-content{
					font-size: $fs-xs;
				}
			}
		}
	}
}