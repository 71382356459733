// vars
$logoWidth: 188px;
$logoNewtralWidth: 85px;
$logoSponsorWidth: 60px;
// styles

.site-header {
  @include fixed;
  border-bottom: $border-xs $white10;

  @include transition(background-color $ts-soft);

  .menu-sticky &,
  .search-focus &,
  .results &,
  .parlamentia & {
    background-color: $space;
  }
  .e-logo {
    a {
      img {
        width: $logoWidth;
      }
    }
  }

  @include respond-above(md) {
    @include flex-center;
    height: $v-unit-xl;
    padding-top: $v-unit-m;
    padding-bottom: $v-unit-m;

    .container {
      @include flex-between;
      @include align-items(center);
    }

    &__wrapper {
      @include display-flex;
      @include align-items(center);

      & > *:not(:last-child) {
        margin-right: $h-unit-xs;
      }

      &__logo {
        background-color: $white10;
        color: $white60;
        border-radius: $unit24;
        padding: $unit10 $unit24;
        width: 355px;
        margin: 0;
        height: 40px;
        display: initial;
      }
    }

    &__link {
      color: $white;
      font-weight: $fw-medium;
      margin-right: $unit24 !important;

      @include touch-hover() {
        color: $primary;
      }
    }

    &__logo {
      color: $white60;
      display: initial;
      vertical-align: baseline;

      > span {
        vertical-align: baseline;
        height: $unit100p;
        display: inline-block;
      }

      > img {
        width: $logoNewtralWidth;
        margin-left: $h-unit-xs;
      }

      &__sponsor {
        > span {
          vertical-align: baseline;
          font-size: $fs-xs;
        }
        > img {
          width: $logoSponsorWidth;
        }
      }
    }
  }

  @include respond-below(md) {
    height: $unit56;
    padding-top: $unit12;
    padding-bottom: $unit12;

    .container {
      padding-left: $unit12;
      padding-right: $unit12;
    }

    .e-logo {
      @include absolute-center;
    }

    .e-dropdown,
    &__link,
    &__logo {
      @include hideit();
    }

    .menu-launched & {
      background-color: $space;
    }
  }
}
