// vars

$center-dark-pad-ver: 72px;

// styles

.b-center-dark {
  background-color: $space;

  .c-pretitle,
  .c-title {
    text-align: center;
  }

  .c-pretitle {
    margin-bottom: $unit12;
    color: $white60;
  }

  .c-title {
    color: $white;
  }

  @include respond-above(sm) {
    padding-top: $center-dark-pad-ver;
    padding-bottom: $center-dark-pad-ver;

    .container--grid {
      @include grid-wrapper(repeat(4, 1fr), $unit24);
      -webkit-row-gap: $unit24;
      -moz-row-gap: $unit24;
      row-gap: $unit24;

      margin-top: $unit48;
    }
  }

  @include respond-below(sm) {
    padding-top: $v-unit-xl;
    padding-bottom: $v-unit-xl;

    .container--grid {
      margin-top: $unit40;
      padding-left: $unit56;
      padding-right: $unit56;

      & > *:not(:last-child) {
        margin-bottom: $v-unit-l;
      }
    }
  }
}
